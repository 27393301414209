import { Collapse, theme, Card } from 'antd';
import { TableComponent } from '../../../Component/Common';
import { CaretRightOutlined } from '@ant-design/icons';
import { BadgeWrapper, CardData, Badge } from './style';

const { Panel } = Collapse;

export const UserComponent = ({awaitingConsult, visitInProgress, visitComplete}) => {
    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none'
    };

    return (
        <>
            <BadgeWrapper>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Awaiting Doctor Exam</div>
                        <Badge color='#faad14'>{awaitingConsult.length}</Badge>
                    </CardData>

                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Doctor Exam In Progress</div>
                        <Badge color='#0096FF'>{visitInProgress.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Visit Complete</div>
                        <Badge color='green'>{visitComplete.length}</Badge>
                    </CardData>
                </Card>
            </BadgeWrapper>
            <Collapse
                bordered={false}
                size='large'
                defaultActiveKey={['1', '2', '3']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel header="Awaiting Doctor Exam" key="1" style={panelStyle}>
                    {awaitingConsult?.length ?
                        <TableComponent data={awaitingConsult} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header="Doctor Exam In Progress" key="2" style={panelStyle}>
                    {visitInProgress?.length ?
                        <TableComponent data={visitInProgress} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header="Visit Complete" key="3" style={panelStyle}>
                    {visitComplete?.length ?
                        <TableComponent data={visitComplete} /> :
                        <p>No Data</p>
                    }
                </Panel>
            </Collapse>
        </>
    )
}