import { useLayoutEffect, useState } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { SpinnerComponent } from "../../Component/Common"
import { LayoutPage } from "../../Component/Layout"
import { CheckSession } from "../../Utils"

export const ProtectedLayout = () => {
    const location = useLocation();
    const [auth, setAuth] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useLayoutEffect(() => {
        let cancelToken;

        const authCheck = async () => {
            try {
                let session = await CheckSession();
                setAuth(session);
            } catch (e) {
                setAuth(false);
            } finally {
                setIsLoading(false);
            }
        };

        authCheck();

        return () => clearTimeout(cancelToken);
    }, [location.pathname]);

    if (isLoading) {
        return <SpinnerComponent />
    }

    return (
        <>
            {!auth ?
                <Navigate to="/" replace /> :
                <LayoutPage><Outlet /></LayoutPage>}
        </>
    )
}