import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';

export const TableSort = ({ data, pagination, onChange, onClick, loading, columns }) => {
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ marginBottom: 8, display: 'block' }} />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>Search</Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>Reset</Button>
          <Button type="link" size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedColumn(dataIndex);
            }}>Filter</Button>
          <Button type="link" size="small" onClick={() => { close(); }}>close</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? text ? text.toString() : '' : text,
  });

  const newColumns = []
  columns.forEach((column, index) => {
    newColumns.push({
      title: column.title,
      dataIndex: column.dataIndex,
      key: column.dataIndex,
      width: index === 0 ? '30%' : '20%',
      ...getColumnSearchProps(column.dataIndex),
      sorter: (a, b) => a[column.dataIndex].length - b[column.dataIndex].length,
    })
  })
  newColumns.push({
    title: 'Action',
    dataIndex: 'operation',
    key: 'operation',
    render: (_, record) => (
      <Space size="middle">
        <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => onClick('edit', record)}>Edit</div>
        <div style={{ color: 'red', cursor: 'pointer' }} onClick={() => onClick('delete', record)}>Delete</div>
      </Space>
    ),
  })

  return <Table columns={newColumns} rowKey={(record) => record.id} dataSource={data} pagination={pagination} onChange={(value) => onChange('table', value)} loading={loading} />;
};

export default TableSort;