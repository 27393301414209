import { CheckSession, Signin } from './authentication';
import { GetPerson, GetPersonWithParams, PostPerson, PutPerson, DeletePerson } from './person';
import { GetPatient, GetPatientWithParams, PostPatient, PutPatient, DeletePatient } from './patient';
import { GetVisit, GetVisitWithParams, PostVisit, PutVisit, DeleteVisit } from './visit';
import { GetEncounter, GetEncounterWithParams, PostEncounter, PutEncounter, DeleteEncounter } from './encounter';
import { GetObs, GetObsWithParams, PostObs, PutObs, DeleteObs } from './obs';
import { GetImage, GetImageWithParams, PostImage, PutImage, DeleteImage } from './image';
import { GetUser, GetUserWithParams, PostUser, PutUser, DeleteUser } from './users';
import { GetConcept, GetConceptWithParams, PostConcept, PutConcept, DeleteConcept } from './concept';
import { GetDataType, GetDataTypeWithParams, PostDataType, PutDataType, DeleteDataType } from './datatype';
import { GetPersonType, GetPersonTypeWithParams, PostPersonType, PutPersonType, DeletePersonType } from './personType';
import { GetLocation, GetLocationWithParams, PostLocation, PutLocation, DeleteLocation } from './location';
import { GetKey, GetKeyWithParams, PostKey, PutKey, DeleteKey } from './licencekey';
import { GetProtocol, GetProtocolWithParams, PostProtocol, PutProtocol, DeleteProtocol } from './licenceKeyProtocol';

export const Services = {
    Signin, CheckSession,
    GetPerson, GetPersonWithParams, PostPerson, PutPerson, DeletePerson,
    GetPatient, GetPatientWithParams, PostPatient, PutPatient, DeletePatient,
    GetVisit, GetVisitWithParams, PostVisit, PutVisit, DeleteVisit,
    GetEncounter, GetEncounterWithParams, PostEncounter, PutEncounter, DeleteEncounter,
    GetObs, GetObsWithParams, PostObs, PutObs, DeleteObs,
    GetImage, GetImageWithParams, PostImage, PutImage, DeleteImage,
    GetUser, GetUserWithParams, PostUser, PutUser, DeleteUser,
    GetConcept, GetConceptWithParams, PostConcept, PutConcept, DeleteConcept,
    GetDataType, GetDataTypeWithParams, PostDataType, PutDataType, DeleteDataType,
    GetPersonType, GetPersonTypeWithParams, PostPersonType, PutPersonType, DeletePersonType,
    GetLocation, GetLocationWithParams, PostLocation, PutLocation, DeleteLocation,
    GetKey, GetKeyWithParams, PostKey, PutKey, DeleteKey,
    GetProtocol, GetProtocolWithParams, PostProtocol, PutProtocol, DeleteProtocol
}