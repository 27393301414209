import * as AuthHelper from './APIHelper';

export const GetUser = () => {
    return AuthHelper.getResponse('/user');
}

export const GetUserWithParams = (params) => {
    return AuthHelper.getResponse(`/user?${params}`);
}

export const PostUser = (payload) => {
    return AuthHelper.postResponse('/user', payload);
}

export const PutUser = (id, payload) => {
    return AuthHelper.putResponse(`/user/${id}`, payload);
}

export const DeleteUser = (id) => {
    return AuthHelper.deleteResponse(`/user/${id}`);
}