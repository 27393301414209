import { useContext, useEffect, useRef } from "react";
import { Context } from "../ContextProvider";
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import { Card, Input, Space, Button, notification } from 'antd';
import { LoginWrapper, CardWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { Services } from '../../__services';
import { CONSTANT, getFromLocalStorage, setToken } from "../../Utils";
import VisilantLogo from '../../Component/Images/VisilantLogo.png';

let access = [CONSTANT.COORDINATOR, CONSTANT.SYSTEMADMIN];

export const Login = () => {
    const [api, contextHolder] = notification.useNotification();
    const { updateState } = useContext(Context);
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    useEffect(() => {
        const checkUser = async () => {
            let user = getFromLocalStorage('user');
            if (user) {
                updateState('page', '/home');
                navigate('/home', { state: { user_type: user.person_type_id === CONSTANT.COORDINATOR ? 'coordinator' : 'user' } });
            }
        }
        checkUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type) => {
        if (type === 'login') {
            if (emailRef.current.input.value && passwordRef.current.input.value) {
                let payload = {
                    email: emailRef.current.input.value,
                    password: passwordRef.current.input.value
                }
                Services.Signin(payload).then(response => {
                    if (response.token) {
                        if (access.includes(response.user.person_type_id)) {
                            updateState('user', response);
                            setToken(response.token);
                            updateState('page', '/home');
                            navigate('/home', { state: { user_type: 'user' } });
                        }
                    } else {
                        openNotificationWithIcon('error', response.data.error)
                    }
                })
            }
        }
    }

    return (
        <>
            <LoginWrapper>
            <img src={VisilantLogo} width={'20%'} alt="" />
                <CardWrapper>
                    <Card title="Login In" bordered={false}>
                        <Space direction="vertical">
                            <Input size="large" placeholder="Username" prefix={<UserOutlined />} ref={emailRef} />
                            <Input.Password size="large" prefix={<LockOutlined />} ref={passwordRef}
                                placeholder="Password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                            <div style={{ textAlign: 'end' }}>
                                <Button type="primary" onClick={() => onClickHandler('login')}>Login</Button>
                            </div>
                        </Space>
                    </Card>
                </CardWrapper>
            </LoginWrapper>
            {contextHolder}
        </>
    )
}