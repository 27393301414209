import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { Layout, Menu, theme } from "antd"
import { TeamOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items = [
    getItem('Dashboard', '1', <PieChartOutlined />),
    getItem('Users', '2', <UserOutlined />),
    getItem('Clinical Protocol', 'sub2', <TeamOutlined />, [
        getItem('Upload Protocols', '3'),
        getItem('Visual Acuity', '4'),
        getItem('Manage Concepts', '5'),
    ])
];

export const Admin = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { token: { colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();
    const location = useLocation();

    const onClickHandler = (type, value) => {
        if (type === 'menu') {
            if (value === '2') {
                navigate('/admin/user', { state: location.state })
            } else if (value === '3') {
                navigate('/admin/clinical/protocol', { state: location.state })
            } else if (value === '4') {
                navigate('/admin/clinical/acuity', { state: location.state })
            } else if (value === '5') {
                navigate('/admin/clinical/concept', { state: location.state })
            }
        }
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div className="demo-logo-vertical" />
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={(e) => onClickHandler('menu', e.key)} />
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }} />
                    <Content style={{ margin: '16px' }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};