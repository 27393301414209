import { Card, Radio, Space, AutoComplete, Input, Divider, notification, Alert, Checkbox } from "antd";
import { DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { DiagnosisWrapper, DiagnosisSection } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

const diagnosisList = ['Active corneal infection', 'Inactive corneal opacity', 'Epithelial Defect', 'Corneal Abrasion', 'Pterygium'];
const lensDiagnosisList = ['Mature cataract', 'Immature cataract', 'Lens Changes', 'Clear Crystalline lens', 'PCIOL', 'Aphakia', 'Corneal opacity prevents diagnosis of lens status'];
const list = [
    { value: 'Pterygium' }, { value: 'Conjunctivitis' },
    { value: 'Subconjunctival hemorrhage' }, { value: 'Presbyopia' },
    { value: 'Active Corneal Infection' }, { value: 'Posterior Segment Screening' },
    { value: 'Cannot be assessed' }
];

const cataractTypeList = ['NS', 'PCS', 'CS', 'n/a'];
const cataractGradeList = ['1', '2', '2+', '3', '3+', '4', 'n/a'];

export const Diagnosis = ({ data, submit, onChange }) => {
    const [api, contextHolder] = notification.useNotification();

    const [rightEyeLens, setRightEyeLens] = useState();
    const [rightEyeAdditionalPathology, setRightEyeAdditionalPathology] = useState([]);
    const [rightEyeBacterial, setRightEyeBacterial] = useState([]);
    const [rightEyeBacterialOther, setRightEyeBacterialOther] = useState();

    const [leftEyeLens, setLeftEyeLens] = useState();
    const [leftEyeAdditionalPathology, setLeftEyeAdditionalPathology] = useState([]);
    const [leftEyeBacterial, setLeftEyeBacterial] = useState([]);
    const [leftEyeBacterialOther, setLeftEyeBacterialOther] = useState();

    const [showRightInfection, setShowRightInfection] = useState(false);
    const [showLeftInfection, setShowLeftInfection] = useState(false);

    const [showCataractTypeRight, setShowCataractTypeRight] = useState(false);
    const [showCataractTypeLeft, setShowCataractTypeLeft] = useState(false);

    const [rightCataractGrade, setRightCataractGrade] = useState();
    const [rightCataractType, setRightCataractType] = useState([]);

    const [leftCataractGrade, setLeftCataractGrade] = useState();
    const [leftCataractType, setLeftCataractType] = useState([]);

    const [rightEyeLensDiagnosis, setRightEyeLensDiagnosis] = useState([]);
    const [leftEyeLensDiagnosis, setLeftEyeLensDiagnosis] = useState([]);
    const [rightEyePathalogyDiagnosis, setRightEyePathalogyDiagnosis] = useState([]);
    const [leftEyePathalogyDiagnosis, setLeftEyePathalogyDiagnosis] = useState([]);

    const [rightCataractTypeDiagnosis, setRightCataractTypeDiagnosis] = useState([]);
    const [rightCataractGradeDiagnosis, setRightCataractGradeDiagnosis] = useState([]);
    const [leftCataractTypeDiagnosis, setLeftCataractTypeDiagnosis] = useState([]);
    const [leftCataractGradeDiagnosis, setLeftCataractGradeDiagnosis] = useState([]);

    // const [otherNoteDiagnosis, setOtherNoteDiagnosis] = useState([]);
    const [rightEyeInput, setRightEyeInput] = useState();
    const [leftEyeInput, setLeftEyeInput] = useState();
    // const [otherNote, setOtherNote] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getDiagnosis();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitDiagnosis();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteDiagnosis(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'rightEyeLens') {
            setRightEyeLens(value);
            if (['Mature cataract', 'Immature cataract', 'Lens Changes'].includes(value)) {
                setShowCataractTypeRight(true);
            } else {
                setShowCataractTypeRight(false);
            }
        } else if (type === 'rightEyeAdditionalPathology') {
            if (value.includes('Active corneal infection')) {
                setShowRightInfection(true);
                onChange('showInfection', true);
                onChange('showMedicationHistory', true);
            } else {
                setShowRightInfection(false);
                onChange('showInfection', false);
                onChange('showMedicationHistory', false);
            }
            setRightEyeAdditionalPathology(value);
        } else if (type === 'leftEyeLens') {
            setLeftEyeLens(value);
            if (['Mature cataract', 'Immature cataract', 'Lens Changes'].includes(value)) {
                setShowCataractTypeLeft(true);
            } else {
                setShowCataractTypeLeft(false);
            }
        } else if (type === 'leftEyeAdditionalPathology') {
            if (value.includes('Active corneal infection')) {
                setShowLeftInfection(true);
                onChange('showInfection', true);
                onChange('showMedicationHistory', true);
            } else {
                setShowLeftInfection(false);
                onChange('showInfection', false);
                onChange('showMedicationHistory', false);
            }
            setLeftEyeAdditionalPathology(value);
        } else if (type === 'rightEyeBacterial') {
            let rightEyeBacterialValue = [...rightEyeBacterial];
            let presentIndex = rightEyeBacterialValue.indexOf(value)
            if (presentIndex !== -1) {
                rightEyeBacterialValue.splice(presentIndex, 1);
            } else {
                rightEyeBacterialValue.push(value)
            }
            setRightEyeBacterial(rightEyeBacterialValue)
        } else if (type === 'leftEyeBacterial') {
            let leftEyeBacterialValue = [...leftEyeBacterial];
            let presentIndex = leftEyeBacterialValue.indexOf(value)
            if (presentIndex !== -1) {
                leftEyeBacterialValue.splice(presentIndex, 1);
            } else {
                leftEyeBacterialValue.push(value)
            }
            setLeftEyeBacterial(leftEyeBacterialValue)
        } else if (type === 'rightEyeBacterialOther') {
            setRightEyeBacterialOther(value);
        } else if (type === 'leftEyeBacterialOther') {
            setLeftEyeBacterialOther(value);
        }
    }

    const getDiagnosis = async () => {
        setRightEyeLensDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS));
        setLeftEyeLensDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS));
        setRightEyePathalogyDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY));
        setLeftEyePathalogyDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY));
        setRightCataractTypeDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPERIGHT));
        setRightCataractGradeDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADERIGHT));
        setLeftCataractTypeDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPELEFT));
        setLeftCataractGradeDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADELEFT));
    }

    const submitDiagnosis = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (rightEyeLens) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISRIGHTEYELENS,
                obs_string: {
                    value: rightEyeLens
                }
            })
        } else if (!rightEyeLensDiagnosis.length) {
            openNotificationWithIcon('error', 'Select the Right Eye Lens Status');
            onChange('submitDiagnosis', false);
            return;
        }
        if (rightEyeAdditionalPathology.length) {
            if (rightEyeAdditionalPathology.includes('Active corneal infection') && (!rightEyeBacterial.length && !rightEyeBacterialOther)) {
                openNotificationWithIcon('error', 'Select the type of Active corneal infection');
                onChange('submitDiagnosis', false);
                return;
            }
            rightEyeBacterial.push(rightEyeBacterialOther);
            let rightEyePathValue = [...rightEyeAdditionalPathology], processedString = '';
            let checkInput = rightEyePathValue.indexOf(4);
            let checkActiveCorneal = rightEyePathValue.indexOf('Active corneal infection');
            if (checkInput !== -1) {
                processedString += `${rightEyeInput}, `;
                rightEyePathValue.splice(checkInput, 1);
            }
            if (checkActiveCorneal !== -1) {
                rightEyePathValue.splice(checkActiveCorneal, 1, `Active corneal infection - ${rightEyeBacterial.join(', ')}`);
            }
            processedString += rightEyePathValue.join(', ');
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY,
                obs_string: {
                    value: processedString
                }
            })
        }
        if (leftEyeLens) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISLEFTEYELENS,
                obs_string: {
                    value: leftEyeLens
                }
            })
        } else if (!leftEyeLensDiagnosis.length) {
            openNotificationWithIcon('error', 'Select the Left Eye Lens Status');
            onChange('submitDiagnosis', false);
            return;
        }
        if (leftEyeAdditionalPathology.length) {
            if (leftEyeAdditionalPathology.includes('Active corneal infection') && (!leftEyeBacterial.length && !leftEyeBacterialOther)) {
                openNotificationWithIcon('error', 'Select the type of Active corneal infection');
                onChange('submitDiagnosis', false);
                return;
            }
            leftEyeBacterial.push(leftEyeBacterialOther);
            let leftEyePathValue = [...leftEyeAdditionalPathology], processedString = '';
            let checkInput = leftEyePathValue.indexOf(4);
            let checkActiveCorneal = leftEyePathValue.indexOf('Active corneal infection');
            if (checkInput !== -1) {
                processedString += `${leftEyeInput}, `;
                leftEyePathValue.splice(checkInput, 1);
            }
            if (checkActiveCorneal !== -1) {
                leftEyePathValue.splice(checkActiveCorneal, 1, `Active corneal infection - ${leftEyeBacterial.join(', ')}`);
            }
            processedString += leftEyePathValue.join(', ');
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY,
                obs_string: {
                    value: processedString
                }
            })
        }
        if (leftCataractGrade) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISCATARACTGRADELEFT,
                obs_string: {
                    value: leftCataractGrade
                }
            })
        }
        if (rightCataractGrade) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISCATARACTGRADERIGHT,
                obs_string: {
                    value: rightCataractGrade
                }
            })
        }
        if (leftCataractType.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISCATARACTTYPELEFT,
                obs_string: {
                    value: leftCataractType.join(', ')
                }
            })
        }
        if (rightCataractType.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISCATARACTTYPERIGHT,
                obs_string: {
                    value: rightCataractType.join(', ')
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let rightEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS);
                    let leftEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS);
                    let rightEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY);
                    let leftEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY);
                    let conTypeR = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPERIGHT);
                    let conGradR = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADERIGHT);
                    let conTypeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPELEFT);
                    let conGradL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADELEFT);
                    if (rightEyeL.length) {
                        setRightEyeLensDiagnosis([...rightEyeLensDiagnosis, ...rightEyeL]);
                    }
                    if (rightEyeD.length) {
                        setRightEyePathalogyDiagnosis([...rightEyePathalogyDiagnosis, ...rightEyeD]);
                    }
                    if (leftEyeL.length) {
                        setLeftEyeLensDiagnosis([...leftEyeLensDiagnosis, ...leftEyeL]);
                    }
                    if (leftEyeD.length) {
                        setLeftEyePathalogyDiagnosis([...leftEyePathalogyDiagnosis, ...leftEyeD]);
                    }
                    if (conTypeR.length) {
                        setRightCataractTypeDiagnosis([...rightCataractTypeDiagnosis, ...conTypeR]);
                    }
                    if (conGradR.length) {
                        setRightCataractGradeDiagnosis([...rightCataractGradeDiagnosis, ...conGradR]);
                    }
                    if (conTypeL.length) {
                        setLeftCataractTypeDiagnosis([...leftCataractTypeDiagnosis, ...conTypeL]);
                    }
                    if (conGradL.length) {
                        setLeftCataractGradeDiagnosis([...leftCataractGradeDiagnosis, ...conGradL]);
                    }
                    if (leftEyeAdditionalPathology.includes('Active corneal infection') || rightEyeAdditionalPathology.includes('Active corneal infection')) {
                        onChange('submitInfectionHistory');
                    } else {
                        onChange('submitEncounter');
                    }
                    resetField();
                    openNotificationWithIcon('success', 'Diagnosis Submitted', '');
                }
            })
        } else {
            onChange('submitEncounter');
        }
    }

    const deleteDiagnosis = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'rightEyeLensDiagnosis') {
                    let newData = [...rightEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyeLensDiagnosis(newData);
                    }
                } else if (path === 'leftEyeLensDiagnosis') {
                    let newData = [...leftEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyeLensDiagnosis(newData);
                    }
                } else if (path === 'rightEyePathalogyDiagnosis') {
                    let newData = [...rightEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyePathalogyDiagnosis(newData);
                    }
                } else if (path === 'leftEyePathalogyDiagnosis') {
                    let newData = [...leftEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyePathalogyDiagnosis(newData);
                    }
                } else if (path === 'rightCataractTypeDiagnosis') {
                    let newData = [...rightCataractTypeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightCataractTypeDiagnosis(newData);
                    }
                } else if (path === 'rightCataractGradeDiagnosis') {
                    let newData = [...rightCataractGradeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightCataractGradeDiagnosis(newData);
                    }
                } else if (path === 'leftCataractTypeDiagnosis') {
                    let newData = [...leftCataractTypeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftCataractTypeDiagnosis(newData);
                    }
                } else if (path === 'leftCataractGradeDiagnosis') {
                    let newData = [...leftCataractGradeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftCataractGradeDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'Diagnosis Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setRightEyeLens('');
        setRightEyeAdditionalPathology([]);
        setRightEyeBacterial([]);
        setRightEyeInput('');
        setLeftEyeLens('')
        setLeftEyeAdditionalPathology([]);
        setLeftEyeBacterial([]);
        setLeftEyeInput('');
        setRightEyeBacterialOther('');
        setLeftEyeBacterialOther('');
        setShowRightInfection(false)
        setShowLeftInfection(false);
        setShowCataractTypeLeft(false);
        setShowCataractTypeRight(false);
        setLeftCataractGrade('');
        setRightCataractGrade('');
        setLeftCataractType('');
        setRightCataractType('');
    }

    return (
        <>
            <Card title={(<> <EyeOutlined /> Physician Diagnosis</>)}>
                <DiagnosisWrapper>
                    <div style={{ width: '100%' }}>
                        <div>Right Eye</div>
                        <Divider />
                        <DiagnosisSection>
                            <div style={{ width: '75%' }}>
                                <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4>
                                <Radio.Group value={rightEyeLens} onChange={(e) => onChangeHandler('rightEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div style={{ width: '100%' }}>
                                <h4 style={{ marginTop: 0 }}>Additional Pathology</h4>
                                <Checkbox.Group onChange={(e) => onChangeHandler('rightEyeAdditionalPathology', e)} value={rightEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        <Checkbox value={4}>Other</Checkbox>
                                        {rightEyeAdditionalPathology.includes(4) ?
                                            <AutoComplete
                                                options={list}
                                                value={rightEyeInput} onChange={e => setRightEyeInput(e)}
                                                style={{ width: 200, marginLeft: 10 }}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                            </AutoComplete> : null}
                                    </Space>
                                </Checkbox.Group>
                            </div>

                        </DiagnosisSection>


                        {showRightInfection || showCataractTypeRight ?
                            <>
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '75%' }}>
                                        {showCataractTypeRight ?
                                            <div style={{ display: 'flex', gap: '1rem' }}>
                                                <div>
                                                    <h4>Cataract Type</h4>
                                                    <Checkbox.Group value={rightCataractType} onChange={(e) => setRightCataractType(e)}>
                                                        <Space direction="vertical">
                                                            {cataractTypeList.map((lens, index) => <Checkbox key={index} value={lens}>{lens}</Checkbox>)}
                                                        </Space>
                                                    </Checkbox.Group>
                                                </div>
                                                <div>
                                                    <h4>Cataract Grade</h4>
                                                    <Radio.Group value={rightCataractGrade} onChange={(e) => setRightCataractGrade(e.target.value)}>
                                                        <Space direction="vertical">
                                                            {cataractGradeList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                                        </Space>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        {showRightInfection ? <><h4>Infection Type <span>(required)</span></h4>
                                            <Space direction="vertical">
                                                <Checkbox value='Suspected Bacterial' checked={rightEyeBacterial.includes('Suspected Bacterial')} onChange={(e) => onChangeHandler('rightEyeBacterial', e.target.value)}>Suspected Bacterial</Checkbox>
                                                <Checkbox value='Suspected Fungal' checked={rightEyeBacterial.includes('Suspected Fungal')} onChange={(e) => onChangeHandler('rightEyeBacterial', e.target.value)}>Suspected Fungal</Checkbox>
                                                <Checkbox value='Suspected viral' checked={rightEyeBacterial.includes('Suspected viral')} onChange={(e) => onChangeHandler('rightEyeBacterial', e.target.value)}>Suspected viral</Checkbox>
                                                {/* <Checkbox value='None' checked={rightEyeBacterial.includes('None')} onChange={(e) => onChangeHandler('rightEyeBacterial', e.target.value)}>None</Checkbox> */}
                                                <Input style={{ width: 200 }} size="medium" onChange={(e) => onChangeHandler('rightEyeBacterialOther', e.target.value)} placeholder="Other infection type"></Input>
                                            </Space></> : null}
                                    </div>
                                </div>
                            </>
                            : null}
                        {rightEyeLensDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {rightEyePathalogyDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {rightCataractTypeDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightCataractTypeDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {rightCataractGradeDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightCataractGradeDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        <div>Left Eye</div>
                        <Divider />
                        <DiagnosisSection>
                            <div style={{ width: '75%' }}>
                                <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4>
                                <Radio.Group value={leftEyeLens} onChange={(e) => onChangeHandler('leftEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>

                            <div style={{ width: '100%' }}>
                                <h4 style={{ marginTop: 0 }}>Additional Pathology</h4>
                                <Checkbox.Group onChange={(e) => onChangeHandler('leftEyeAdditionalPathology', e)} value={leftEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        <Checkbox value={4}>Other</Checkbox>
                                        {leftEyeAdditionalPathology.includes(4) ?
                                            <AutoComplete
                                                options={list}
                                                value={leftEyeInput} onChange={e => setLeftEyeInput(e)}
                                                style={{ width: 200, marginLeft: 10 }}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                            </AutoComplete> : null}
                                    </Space>
                                </Checkbox.Group>
                            </div>
                        </DiagnosisSection>

                        {showLeftInfection || showCataractTypeLeft ?
                            <>
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '75%' }}>
                                        {showCataractTypeLeft ?
                                            <div style={{ display: 'flex', gap: '1rem' }}>
                                                <div>
                                                    <h4>Cataract Type</h4>
                                                    <Checkbox.Group value={leftCataractType} onChange={(e) => setLeftCataractType(e)}>
                                                        <Space direction="vertical">
                                                            {cataractTypeList.map((lens, index) => <Checkbox key={index} value={lens}>{lens}</Checkbox>)}
                                                        </Space>
                                                    </Checkbox.Group>
                                                </div>
                                                <div>
                                                    <h4>Cataract Grade</h4>
                                                    <Radio.Group value={leftCataractGrade} onChange={(e) => setLeftCataractGrade(e.target.value)}>
                                                        <Space direction="vertical">
                                                            {cataractGradeList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                                        </Space>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        {showLeftInfection ? <><h4>Infection Type <span>(required)</span></h4>
                                            <Space direction="vertical">
                                                <Checkbox value='Suspected Bacterial' checked={leftEyeBacterial.includes('Suspected Bacterial')} onChange={(e) => onChangeHandler('leftEyeBacterial', e.target.value)}>Suspected Bacterial</Checkbox>
                                                <Checkbox value='Suspected Fungal' checked={leftEyeBacterial.includes('Suspected Fungal')} onChange={(e) => onChangeHandler('leftEyeBacterial', e.target.value)}>Suspected Fungal</Checkbox>
                                                <Checkbox value='Suspected viral' checked={leftEyeBacterial.includes('Suspected viral')} onChange={(e) => onChangeHandler('leftEyeBacterial', e.target.value)}>Suspected viral</Checkbox>
                                                {/* <Checkbox value='None' checked={leftEyeBacterial.includes('None')} onChange={(e) => onChangeHandler('leftEyeBacterial', e.target.value)}>None</Checkbox> */}
                                                <Input style={{ width: 200 }} size="medium" onChange={(e) => onChangeHandler('leftEyeBacterialOther', e.target.value)} placeholder="Other infection type"></Input>
                                            </Space></> : null}
                                    </div>
                                </div>
                            </>
                            : null}
                        {leftEyeLensDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>)}
                        {leftEyePathalogyDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {leftCataractTypeDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftCataractTypeDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {leftCataractGradeDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftCataractGradeDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>
                </DiagnosisWrapper>
            </Card>
            {contextHolder}
        </>
    )
}