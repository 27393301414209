import { useState, useRef, useEffect } from "react";
import { Button, notification } from "antd";
import { Services } from "../../../../__services";
import { ModalComponent, TableSort } from "../../../../Component/Common";
import { AddEditConcept } from "./AddEdit";

const columns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Description', dataIndex: 'description' },
    { title: 'Created At', dataIndex: 'created_at' },
]

export const Concept = () => {
    const [allConcepts, setAllConcepts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [valueEdit, setValueEdit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [conceptId, setConceptId] = useState();
    const [conceptName, setConceptName] = useState();
    const [conceptDescription, setConceptDescription] = useState();
    const [conceptDataType, setConceptDataType] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 10
    })
    const [tableLoading, setTableLoading] = useState(false);
    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current) {
            getAllConcepts();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickHandler = (type, value) => {
        if (type === 'addConcept') {
            setModalTitle('Add Concept');
            setShowModal(true);
        } else if (type === 'edit') {
            setModalTitle('Edit Concept');
            setShowModal(true);
            setConceptId(value.id);
            setConceptName(value.name);
            setConceptDescription(value.description);
            setConceptDataType(value.datatype_id);
            setValueEdit(value);
        } else if (type === 'delete') {
            setModalTitle('Are you Sure');
            setShowModal(true);
            setValueEdit(value);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'name') {
            setConceptName(value);
        } else if (type === 'description') {
            setConceptDescription(value);
        } else if (type === 'datatype') {
            setConceptDataType(value);
        } else if (type === 'table') {
            getAllConcepts(value);
        }
    }

    const getAllConcepts = async (value = pagination) => {
        try {
            setTableLoading(true);
            let params = `take=10&skip=${(value.current - 1) * 10}`;
            let concept = await Services.GetConceptWithParams(params);
            setAllConcepts(concept.rows);
            setPagination({
                ...pagination,
                ...value,
                total: concept.total
            })
            setTableLoading(false);
        } catch (err) {
            console.log('Fetch Concept Exceptions :', err);
            setTableLoading(false);
        }
    }

    const saveConcept = async () => {
        try {
            if (valueEdit) {
                if (modalTitle === 'Edit Concept') {
                    editConcept();
                } else {
                    deleteConcept();
                }
                return;
            }
            if (conceptName && conceptDataType) {
                setLoading(true);
                let payload = {
                    name: conceptName,
                    description: conceptDescription,
                    datatype_id: conceptDataType
                }
                let saveConcept = await Services.PostConcept(payload);
                if (saveConcept.id) {
                    setTimeout(() => {
                        resetField();
                    }, 2000)
                    openNotificationWithIcon('success', 'Concept created Successfully');
                    getAllConcepts();
                } else {
                    openNotificationWithIcon('error', 'Data not saved');
                }
            } else {
                setTimeout(() => {
                    openNotificationWithIcon('error', 'Enter all details');
                    setLoading(false);
                }, 1000)
            }

        } catch (err) {
            console.log('Save Concept Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const editConcept = async () => {
        try {
            let payload = {
                name: conceptName,
                description: conceptDescription,
                datatype_id: conceptDataType
            }
            let editConcept = await Services.PutConcept(valueEdit.id, payload);
            if (editConcept.name) {
                setTimeout(() => {
                    resetField();
                }, 2000)
                openNotificationWithIcon('success', 'Saved Successfully');
                getAllConcepts();
            } else {
                openNotificationWithIcon('error', 'Data not saved');
            }
        } catch (err) {
            console.log('Save Concept Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const deleteConcept = async () => {
        try {
            let deleteConcept = await Services.DeleteConcept(valueEdit.id);
            if (deleteConcept) {
                openNotificationWithIcon('success', 'Deleted Successfully');
                getAllConcepts();
                setTimeout(() => {
                    resetField();
                }, 2000)
            }
        } catch (err) {
            console.log('Delete Concept Exception :', err);
            openNotificationWithIcon('error', 'Data not deleted');
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const resetField = () => {
        setShowModal(false);
        setValueEdit(undefined);
        setLoading(false);
        setConceptId();
        setConceptName();
        setConceptDescription();
        setConceptDataType();

    }

    return (
        <div>
            <div style={{ textAlign: 'end' }}>
                <Button type="primary" onClick={() => onClickHandler('addConcept')}>Create New Concept</Button>
                <br />
                <br />
            </div>
            <TableSort data={allConcepts} pagination={pagination} onChange={onChangeHandler} onClick={onClickHandler} columns={columns} loading={tableLoading} />
            {showModal ?
                <ModalComponent
                    open={showModal}
                    title={modalTitle}
                    loading={loading}
                    saveData={saveConcept}
                    onCancel={() => resetField()}>
                    {modalTitle !== 'Are you Sure' ? <AddEditConcept
                        onChange={onChangeHandler}
                        conceptId={conceptId}
                        conceptName={conceptName}
                        conceptDescription={conceptDescription}
                        conceptDataType={conceptDataType}
                    /> : <p>This will delete the concept "{valueEdit.name}" permanently from the system.</p>}
                </ModalComponent>
                : null}
            {contextHolder}
        </div>
    )
}