import { useState, useEffect, useRef } from "react";
import { Card, AutoComplete, Input, Space, notification, Alert, Radio, Checkbox } from "antd";
import { DeleteFilled } from '@ant-design/icons';
import { EMRWrapper, EMRSection } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

// const visualAndPinoleData = [{ value: "6/6" }, { value: "6/9" }, { value: "6/12" }, { value: "6/18" }, { value: "6/36" }, { value: "6/60" }, { value: "5/60" }, { value: "4/60" }, { value: "3/60" },
// { value: "2/60" }, { value: "1/60" }, { value: "Finger Counts" }, { value: "Hand movements" }, { value: "Light Perception" }, { value: "No Light Perception" }];

const visualAndPinoleData = [{value: "6/6"},
    {value: "6/6P"},
    {value: "6/9"},
    {value: "6/9P"},
    {value: "6/12"},
    {value: "6/12P"},
    {value: "6/18"},
    {value: "6/18P"},
    {value: "6/24"},
    {value: "6/24P"},
    {value: "6/36"},
    {value: "3/36P"},
    {value: "6/60"},
    {value: "6/60P"},
    {value: "5/60"},
    {value: "4/60"},
    {value: "3/60"},
    {value: "2/60"},
    {value: "1/60"},
    {value: "1/2/60"},
    {value: "Finger Counts"},
    {value: "Hand movements"},
    {value: "Light Perception"},
    {value: "No Light Perception"},
];

const lacrimalDuctData = ["Free", "Not free with clear fluid", "Not free with pus", "n/a"];

export const EMR = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();

    const [pinholeRightDignosis, setPinholeRightDignosis] = useState([]);
    const [pinholeLeftDignosis, setPinholeLeftDignosis] = useState([]);
    const [acuityRightDignosis, setAcuityRightDignosis] = useState([]);
    const [acuityLeftDignosis, setAcuityLeftDignosis] = useState([]);
    const [iopRightDiagnosis, setIopRightDiagnosis] = useState([]);
    const [iopLeftDiagnosis, setIopLeftDiagnosis] = useState([]);
    const [lacrimalDuctDiagnosis, setLacrimalDuctDiagnosis] = useState([]);
    const [bloodSugarDiagnosis, setBloodSugarDiagnosis] = useState([]);

    const [rightAll, setRightAll] = useState(false);
    const [leftAll, setLeftAll] = useState(false);
    const [visualRight, setVisualRight] = useState('');
    const [visualLeft, setVisualLeft] = useState('');
    const [pinholeRight, setPinholeRight] = useState('');
    const [pinholeLeft, setPinholeLeft] = useState('');
    const [iopRight, setIopRight] = useState();
    const [iopLeft, setIopLeft] = useState();
    const [lacrimalDuct, setLacrimalDuct] = useState('');
    const [bloodSugar, setBloodSugar] = useState('');
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getEMR();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitEMR();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteEMR(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'lacrimalDuct') {
            setLacrimalDuct(value)
        } else if (type === 'rightAll') {
            if (value) {
                setRightAll(true);
                setVisualRight('n/a');
                setPinholeRight('n/a');
                setIopRight('n/a');
            } else {
                setRightAll(false);
                setVisualRight('');
                setPinholeRight('');
                setIopRight();
            }
        } else if (type === 'leftAll') {
            if (value) {
                setLeftAll(true);
                setVisualLeft('n/a');
                setPinholeLeft('n/a');
                setIopLeft('n/a')
            } else {
                setLeftAll(false);
                setVisualLeft('');
                setPinholeLeft('');
                setIopLeft();
            }
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getEMR = async () => {
        setAcuityLeftDignosis(data.obs.filter(ob => ob.concept_id === CONSTANT.VALEFT));
        setAcuityRightDignosis(data.obs.filter(ob => ob.concept_id === CONSTANT.VARIGHT));
        setPinholeLeftDignosis(data.obs.filter(ob => ob.concept_id === CONSTANT.PINEHOLELEFT));
        setPinholeRightDignosis(data.obs.filter(ob => ob.concept_id === CONSTANT.PINEHOLERIGHT));
        setIopLeftDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.EMRIOPLEFT));
        setIopRightDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.EMRIOPRIGHT));
        setBloodSugarDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.EMRBLOODSUGAR));
        setLacrimalDuctDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCT))
    }

    const submitEMR = async () => {
        let user = await getFromLocalStorage('user');
        let allData = []
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (visualRight.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.VARIGHT,
                obs_string: {
                    value: visualRight
                }
            })
        }
        if (visualLeft.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.VALEFT,
                obs_string: {
                    value: visualLeft
                }
            })
        }
        if (pinholeRight.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.PINEHOLERIGHT,
                obs_string: {
                    value: pinholeRight
                }
            })
        }
        if (pinholeLeft.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.PINEHOLELEFT,
                obs_string: {
                    value: pinholeLeft
                }
            })
        }
        if (iopRight) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EMRIOPRIGHT,
                obs_string: {
                    value: iopRight
                }
            })
        }
        if (iopLeft) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EMRIOPLEFT,
                obs_string: {
                    value: iopLeft
                }
            })
        }
        if (bloodSugar.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EMRBLOODSUGAR,
                obs_string: {
                    value: bloodSugar
                }
            })
        }
        if (lacrimalDuct.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EMRLACRIMALDUCT,
                obs_string: {
                    value: lacrimalDuct
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let vaL = response.filter(ob => ob.concept_id === CONSTANT.VALEFT);
                    let vaR = response.filter(ob => ob.concept_id === CONSTANT.VARIGHT);
                    let piL = response.filter(ob => ob.concept_id === CONSTANT.PINEHOLELEFT);
                    let piR = response.filter(ob => ob.concept_id === CONSTANT.PINEHOLERIGHT);
                    let ioL = response.filter(ob => ob.concept_id === CONSTANT.EMRIOPLEFT);
                    let ioR = response.filter(ob => ob.concept_id === CONSTANT.EMRIOPRIGHT);
                    let blsug = response.filter(ob => ob.concept_id === CONSTANT.EMRBLOODSUGAR);
                    let lacrDuct = response.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCT);
                    if (vaL.length) {
                        setAcuityLeftDignosis([...acuityLeftDignosis, ...vaL]);
                    }
                    if (vaR.length) {
                        setAcuityRightDignosis([...acuityRightDignosis, ...vaR]);
                    }
                    if (piL.length) {
                        setPinholeLeftDignosis([...pinholeLeftDignosis, ...piL]);
                    }
                    if (piR.length) {
                        setPinholeRightDignosis([...pinholeRightDignosis, ...piR]);
                    }
                    if (ioL.length) {
                        setIopLeftDiagnosis([...iopLeftDiagnosis, ...ioL]);
                    }
                    if (ioR.length) {
                        setIopRightDiagnosis([...iopRightDiagnosis, ...ioR]);
                    }
                    if (blsug.length) {
                        setBloodSugarDiagnosis([...bloodSugarDiagnosis, ...blsug]);
                    }
                    if (lacrDuct.length) {
                        setLacrimalDuctDiagnosis([...lacrimalDuctDiagnosis, ...lacrDuct]);
                    }
                    resetField();
                    openNotificationWithIcon('success', 'EMR Submitted');
                }
            })
        }
    }

    const deleteEMR = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'acuityRightDignosis') {
                    let newData = [...acuityRightDignosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setAcuityRightDignosis(newData);
                    }
                } else if (path === 'pinholeRightDignosis') {
                    let newData = [...pinholeRightDignosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setPinholeRightDignosis(newData);
                    }
                } else if (path === 'iopRightDiagnosis') {
                    let newData = [...iopRightDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setIopRightDiagnosis(newData);
                    }
                } else if (path === 'acuityLeftDignosis') {
                    let newData = [...acuityLeftDignosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setAcuityLeftDignosis(newData);
                    }
                } else if (path === 'pinholeLeftDignosis') {
                    let newData = [...pinholeLeftDignosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setPinholeLeftDignosis(newData);
                    }
                } else if (path === 'iopLeftDiagnosis') {
                    let newData = [...iopLeftDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setIopLeftDiagnosis(newData);
                    }
                } else if (path === 'lacrimalDuctDiagnosis') {
                    let newData = [...lacrimalDuctDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLacrimalDuctDiagnosis(newData);
                    }
                } else if (path === 'bloodSugarDiagnosis') {
                    let newData = [...bloodSugarDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setBloodSugarDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'EMR Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setVisualRight('');
        setPinholeRight('');
        setVisualLeft('');
        setPinholeLeft('');
        setIopRight();
        setIopLeft();
        setBloodSugar('');
        setLacrimalDuct('');
    }


    return (
        <div>
            <Card title={(<>EMR Data</>)}>
                <EMRWrapper>
                    <EMRSection>
                        <AutoComplete options={visualAndPinoleData}
                            style={{ width: 200, marginTop: 24 }}
                            placeholder="Visual Acuity Right"
                            value={visualRight} onChange={(e) => setVisualRight(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                        <AutoComplete options={visualAndPinoleData}
                            style={{ width: 200 }}
                            placeholder="Pinhole Acuity Right"
                            value={pinholeRight} onChange={(e) => setPinholeRight(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                        <Input placeholder="IOP Right" value={iopRight} onChange={(e) => setIopRight(e.target.value)} />
                        <div>
                            {acuityRightDignosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'acuityRightDignosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                            {pinholeRightDignosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'pinholeRightDignosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                            {iopRightDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'iopRightDiagnosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                        </div>
                    </EMRSection>
                    <EMRSection style={{ alignItems: 'baseline', gap: '0rem' }}>
                        <Checkbox checked={rightAll} onChange={(e) => onChangeHandler('rightAll', e.target.checked)}><h4 style={{ margin: '0px' }}>n/a</h4></Checkbox>
                        <Checkbox onChange={(e) => setVisualRight(e.target.checked ? 'n/a' : '')} style={{ marginTop: '7px' }}></Checkbox>
                        <Checkbox onChange={(e) => setPinholeRight(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}></Checkbox>
                        <Checkbox onChange={(e) => setIopRight(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}></Checkbox>
                    </EMRSection>
                    <EMRSection>
                        <AutoComplete options={visualAndPinoleData}
                            style={{ width: 200, marginTop: 24 }}
                            placeholder="Visual Acuity Left"
                            value={visualLeft} onChange={(e) => setVisualLeft(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                        <AutoComplete options={visualAndPinoleData}
                            style={{ width: 200 }}
                            placeholder="Pinhole Acuity Left"
                            value={pinholeLeft} onChange={(e) => setPinholeLeft(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                        <Input placeholder="IOP Left" value={iopLeft} onChange={(e) => setIopLeft(e.target.value)} />
                        <div>
                            {acuityLeftDignosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'acuityLeftDignosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                            {pinholeLeftDignosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'pinholeLeftDignosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                            {iopLeftDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'iopLeftDiagnosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                        </div>
                    </EMRSection>
                    <EMRSection style={{ alignItems: 'baseline', gap: '0rem' }}>
                        <Checkbox checked={leftAll} onChange={(e) => onChangeHandler('leftAll', e.target.checked)}><h4 style={{ margin: '0px' }}>n/a</h4></Checkbox>
                        <Checkbox onChange={(e) => setVisualLeft(e.target.checked ? 'n/a' : '')} style={{ marginTop: '7px' }}></Checkbox>
                        <Checkbox onChange={(e) => setPinholeLeft(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}></Checkbox>
                        <Checkbox onChange={(e) => setIopLeft(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}></Checkbox>
                    </EMRSection>
                    <EMRSection>
                        <div>
                            <h4 style={{ margin: 0 }}>Lacrimal Duct testing:</h4>
                            <Radio.Group value={lacrimalDuct} onChange={(e) => onChangeHandler('lacrimalDuct', e.target.value)}>
                                <Space direction="vertical">
                                    {lacrimalDuctData.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                </Space>
                            </Radio.Group>
                        </div>
                        <div style={{ display: 'flex',gap: '1rem', alignItems: 'baseline'}}>
                            <Input placeholder="Blood Sugar" value={bloodSugar} onChange={(e) => setBloodSugar(e.target.value)} />
                            <Checkbox onChange={(e) => setBloodSugar(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}>n/a</Checkbox>
                        </div>
                        <div>
                            {lacrimalDuctDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'lacrimalDuctDiagnosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                            {bloodSugarDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'bloodSugarDiagnosis')} style={{ color: 'red' }} />
                                    </div>)
                            } /></div>
                            )}
                        </div>
                    </EMRSection>
                </EMRWrapper>
            </Card>
            {contextHolder}
        </div>
    )
}