
import { Card, notification, Alert } from "antd";
import { DeleteFilled, CommentOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import { useState, useEffect, useRef } from "react";
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

export const TVLDoctorNote = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();
    const [notesDiagnosis, setNotesDiagnosis] = useState([])
    const [notes, setNotes] = useState('');

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getNotes();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitNotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value) => {
        if (type === 'deleteNotes') {
            deleteNotes(value);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'notes') {
            setNotes(value);
        }
    }

    const getNotes = async () => {
        setNotesDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DOCTORNOTES));
    }

    const submitNotes = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (notes.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DOCTORNOTES,
                obs_string: {
                    value: notes
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let doctorNotes = response.filter(ob => ob.concept_id === CONSTANT.DOCTORNOTES);
                    if (doctorNotes.length) {
                        setNotesDiagnosis([...notesDiagnosis, ...doctorNotes]);
                    }
                    resetField();
                    openNotificationWithIcon('success', 'Notes Submitted', '');
                }
            });
        }
    }

    const deleteNotes = (id) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                let newData = [...notesDiagnosis];
                let index = newData.findIndex(dt => dt.id === id);
                if (index !== -1) {
                    newData.splice(index, 1);
                    setNotesDiagnosis(newData);
                }
                openNotificationWithIcon('success', 'Notes Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setNotes('');
    }

    return (
        <div>
            <Card title={(<> <CommentOutlined /> Notes</>)}>
                <TextArea placeholder="Notes (not shared with the patient)" value={notes} onChange={(e) => onChangeHandler('notes', e.target.value)} />
                {notesDiagnosis.map(note => <div key={note.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{note.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteNotes', note.id)} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
            </Card>
            {contextHolder}
        </div>
    )
}