import { Input, Space, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Dragger } = Upload;

export const AddEditKey = ({ onChange, name, description, protocol }) => {

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                {!protocol ?
                    <>
                        <div>The program key creates a unique screening protocol for each screening program</div>
                        <Input value={name} onChange={e => onChange('name', e.target.value)} placeholder="Name" />
                        <TextArea value={description} rows={4} placeholder="Description" onChange={e => onChange('description', e.target.value)} />
                    </>
                    : <>
                        {!description ? <Dragger name='file' multiple={false} onChange={(e) => onChange('file', e.file)} onDrop={(e) => onChange('description', e.target.files)} customRequest={() => {}}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                            :
                            <>
                                <Input value={name} onChange={e => onChange('name', e.target.value)} placeholder="Name" />
                                <TextArea value={description} rows={20} placeholder="Description" onChange={e => onChange('description', e.target.value)} />
                            </>
                        }
                    </>
                }
            </Space>
        </>
    )
}