import { useEffect, useRef, useState } from "react";
import { Services } from '../../../../__services';
import { ImageWrapper, ImageSection, Image } from './style';

export const PatientImage = ({ data }) => {
    const isFirstRender = useRef(true);
    const [allImages, setAllImages] = useState([]);

    useEffect(() => {
        if (isFirstRender.current) {
            getImage();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getImage = async () => {
        try {
            let params = `patient_id=${data.patient_id}&visit_id=${data.visit_id}`;
            let patientImage = await Services.GetImageWithParams(params);
            setAllImages(patientImage.rows);
        } catch (err) {
            console.log('Patient Image Exception :', err);
        }
    }
    return (
        <ImageWrapper>
            <ImageSection>
                {allImages.map(image =>
                    image.type === 'right' ? <a href={image.image_path} key={image.id}><Image src={image.image_path} alt="" /></a> : null
                )}
            </ImageSection >
            <ImageSection>
                {allImages.map(image =>
                    image.type === 'left' ? <a href={image.image_path} key={image.id}><Image src={image.image_path} alt="" /></a> : null
                )}
            </ImageSection >
        </ImageWrapper >
    )
}