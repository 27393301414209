import * as AuthHelper from './APIHelper';

export const GetEncounter = () => {
    return AuthHelper.getResponse('/encounter');
}

export const GetEncounterWithParams = (params) => {
    return AuthHelper.getResponse(`/encounter?${params}`);
}

export const PostEncounter = (payload) => {
    return AuthHelper.postResponse('/encounter', payload);
}

export const PutEncounter = (id, payload) => {
    return AuthHelper.putResponse(`/encounter/${id}`, payload);
}

export const DeleteEncounter = (id) => {
    return AuthHelper.deleteResponse(`/encounter/${id}`);
}