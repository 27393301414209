import * as AuthHelper from './APIHelper';

export const GetConcept = () => {
    return AuthHelper.getResponse('/concept');
}

export const GetConceptWithParams = (params) => {
    return AuthHelper.getResponse(`/concept?${params}`);
}

export const PostConcept = (payload) => {
    return AuthHelper.postResponse('/concept', payload);
}

export const PutConcept = (id, payload) => {
    return AuthHelper.putResponse(`/concept/${id}`, payload);
}

export const DeleteConcept = (id) => {
    return AuthHelper.deleteResponse(`/concept/${id}`);
}