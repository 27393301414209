/**
 * Save value to browser localstorage
 * @param {string} item string
 * @param {*} data 
 * @returns 
 */

export const saveToLocalStorage = (item, data) => {
    return localStorage.setItem(item, JSON.stringify(data));
}

/**
 * Return Value from localstorage
 * @param {string} item 
 * @returns 
 */
export const getFromLocalStorage = (item) => {
    return JSON.parse(localStorage.getItem(item));
}