import { useState, useEffect, useRef } from "react";
import { Card, Input, Divider, Checkbox, notification, Alert, DatePicker } from "antd";
import { DeleteFilled } from '@ant-design/icons';
import { InputWrapper } from './style';
// import { SVGLibrary } from "../../../Component/Common";
// import Icon from '@ant-design/icons';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

const CheckboxGroup = Checkbox.Group;

const envList = [
    { svg: 'contactLens', value: 'Any contact lens wear' },
    { svg: 'poorContactLensHygiene', value: 'Poor contact lens hygiene' },
    { svg: 'waterExposure', value: 'Water exposure (swimming pool, natural)' }
]

const ocularList = [
    { svg: 'topicalSteroidUse', value: 'Topical steroid use' },
    { svg: 'traditionalEyeMedicine', value: 'Traditional eye medicine usage' },
    { svg: 'recentOcularTrauma', value: 'Recent ocular trauma' },
    { svg: 'herpeticEyeDisease', value: 'Prior herpetic eye disease' },
    { svg: 'priorCornealUlcer', value: 'Prior corneal ulcer in same eye' },
    { svg: 'recentOcularSurgery', value: 'Recent ocular surgery' },
    { svg: 'keratoplasty', value: 'Prior keratoplasty in same eye' }
]

const medicalHistoryList = [
    { svg: 'diabetes', value: 'Diabetes mellitus' },
    { svg: 'systemicImmunosuppression', value: 'Systemic immunosuppression' },
]

const otherRiskList = [
    { svg: 'other', value: 'Other (please specify)' },
    { svg: 'noRisk', value: 'No infection risks' }
]

export const InfectionHistory = ({ data, submit, onChange }) => {
    const [api, contextHolder] = notification.useNotification();
    const [infections, setInfections] = useState([]);
    const [infectionSymptomDays, setInfectionSymptomDays] = useState([]);
    const [infectionPresentationDays, setInfectionPresentationDays] = useState([]);
    const [infectionRiskFactor, setInfectionRiskFactor] = useState([]);
    const [symptonDays, setSymptonDays] = useState();
    const [presentationDays, setPresentationDays] = useState();
    const [otherRisk, setOtherRisk] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getInfectionHistory();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitInfectionHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteInfection') {
            deleteInfection(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'infection') {
            setInfections(value);
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getInfectionHistory = async () => {
        setInfectionSymptomDays(data.obs.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYSYMPTOMSDAYS))
        setInfectionPresentationDays(data.obs.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYPRESENTATIONDATE))
        setInfectionRiskFactor(data.obs.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYRISKFACTOR))
    }

    const submitInfectionHistory = async () => {
        let user = await getFromLocalStorage('user');
        let allData = []
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (!symptonDays || !presentationDays || !infections.length) {
            openNotificationWithIcon('error', 'Fill Infection History');
            return;
        }
        if (symptonDays) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.INFECTIONHISTORYSYMPTOMSDAYS,
                obs_number: {
                    value: symptonDays
                }
            })
        }
        if (presentationDays) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.INFECTIONHISTORYPRESENTATIONDATE,
                obs_string: {
                    value: presentationDays
                }
            })
        }
        if (infections.length) {
            let other = infections.indexOf('Other (please specify)');
            if (other !== -1) {
                infections.splice(other, 1);
                infections.push(otherRisk);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.INFECTIONHISTORYRISKFACTOR,
                obs_string: {
                    value: infections.join(', ')
                }
            })
        }
        Services.PostObs(allData).then(response => {
            if (response.status !== 400) {
                let infSymD = response.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYSYMPTOMSDAYS);
                let presD = response.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYPRESENTATIONDATE);
                let riskF = response.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYRISKFACTOR);
                if (infSymD.length) {
                    setInfectionSymptomDays([...infectionSymptomDays, ...infSymD]);
                }
                if (presD.length) {
                    setInfectionPresentationDays([...infectionPresentationDays, ...presD]);
                }
                if (riskF.length) {
                    setInfectionRiskFactor([...infectionRiskFactor, ...riskF]);
                }
                onChange('submitEncounter');
                resetField();
                openNotificationWithIcon('success', 'Infection History Submitted');
            }
        })
    }

    const deleteInfection = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'infectionSymptomDays') {
                    let newData = [...infectionSymptomDays];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionSymptomDays(newData);
                    }
                } else if (path === 'infectionPresentationDays') {
                    let newData = [...infectionPresentationDays];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionPresentationDays(newData);
                    }
                } else {
                    let newData = [...infectionRiskFactor];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionRiskFactor(newData);
                    }
                }
                openNotificationWithIcon('success', 'Infection History Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setSymptonDays('');
        setPresentationDays('');
        setInfections([]);
        setOtherRisk('');
    }

    return (
        <div>
            <Card title={(<>Infection History</>)}>
                <InputWrapper>
                    <div>
                        Days since symptoms began <span>(required)</span> :
                        <Input size="medium" type="number" min="1" placeholder="days" value={symptonDays} onChange={e => setSymptonDays(e.target.value)} />
                    </div>
                    <div>
                        Date of first presenting to eye hospital <span>(required)</span> :
                        {/* <Input size="medium" type="number" min="0" placeholder="0 (today) days" value={presentationDays} onChange={e => setPresentationDays(e.target.value)} /> */}
                        <DatePicker size="medium" style={{ width: 350, display: 'block' }} format="YYYY-MM-DD" onChange={(e, format) => setPresentationDays(format)} />
                    </div>
                </InputWrapper>
                <br />
                <div><b>Infection risk factors</b> (required, enter all that apply): </div>
                <Divider />

                {/* <div>
                        <h5 style={{margin: '10px 0px'}}>Environmental risks:</h5>
                        <CheckboxGroup style={{ flexDirection: 'column'}} value={infections} onChange={(e) => onChangeHandler('infection', e)}>
                            {envList.map(infection =>
                                <Checkbox style={{marginInlineStart: 0}} value={infection.value}>{infection.value}</Checkbox>
                            )}
                        </CheckboxGroup>
                    </div>
                    <div>
                        <h5 style={{margin: '10px 0px'}}>Ocular history risks:</h5>
                        <CheckboxGroup style={{ flexDirection: 'column'}} value={infections} onChange={(e) => onChangeHandler('infection', e)}>
                            {ocularList.map(infection =>
                                <Checkbox style={{marginInlineStart: 0}} value={infection.value}>{infection.value}</Checkbox>
                            )}
                        </CheckboxGroup>
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div>
                            <h5 style={{margin: '10px 0px'}}>Medical history risks:</h5>
                            <CheckboxGroup style={{ flexDirection: 'column'}} value={infections} onChange={(e) => onChangeHandler('infection', e)}>
                                {medicalHistoryList.map(infection =>
                                    <Checkbox style={{marginInlineStart: 0}} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </CheckboxGroup>
                        </div>
                       
                        <br/>
                        <div>
                            <h5 style={{margin: '10px 0px'}}>Enter Other Risk:</h5>
                            <CheckboxGroup style={{ flexDirection: 'column'}} value={infections} onChange={(e) => onChangeHandler('infection', e)}>
                                {otherRiskList.map(infection =>
                                    <Checkbox style={{marginInlineStart: 0}} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </CheckboxGroup>
                        </div>
                        
                    </div> */}
                    
                <CheckboxGroup value={infections} style={{ width: '100%' }} onChange={(e) => onChangeHandler('infection', e)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h5 style={{ margin: '10px 0px' }}>Environmental risks:</h5>
                            {envList.map(infection =>
                                <Checkbox style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h5 style={{ margin: '10px 0px' }}>Ocular history risks:</h5>
                            {ocularList.map(infection =>
                                <Checkbox style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                            )}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5 style={{ margin: '10px 0px' }}>Medical history risks:</h5>
                                {medicalHistoryList.map(infection =>
                                    <Checkbox style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </div>

                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5 style={{ margin: '10px 0px' }}>Enter Other Risk:</h5>
                                {otherRiskList.map(infection =>
                                    <Checkbox style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </div>
                        </div>
                    </div>
                </CheckboxGroup>


                {infections.includes('Other (please specify)') ?
                    <>
                        <br />
                        <Input placeholder="Enter text" value={otherRisk} onChange={e => setOtherRisk(e.target.value)} style={{ width: 300 }} />
                    </> :
                    null}
                {infectionSymptomDays.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_number.value} days since symptom began</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionSymptomDays')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionPresentationDays.map(presDay => <div key={presDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{presDay?.obs_string?.value} - presentation to eye hospital</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', presDay.id, 'infectionPresentationDays')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionRiskFactor.map(riskFactor => <div key={riskFactor.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{riskFactor.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', riskFactor.id, 'infectionRiskFactor')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
            </Card>
            {contextHolder}
        </div>
    )
}