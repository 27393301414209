import styled from 'styled-components';
import background from '../../Component/Images/login.png';

export const LoginWrapper = styled.div`
    background: url(${background});
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const CardWrapper = styled.div`
    margin: 0px;
`;

