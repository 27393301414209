import * as AuthHelper from './APIHelper';

export const GetPerson = () => {
    return AuthHelper.getResponse('/person');
}

export const GetPersonWithParams = (params) => {
    return AuthHelper.getResponse(`/person?${params}`);
}

export const PostPerson = (payload) => {
    return AuthHelper.postResponse('/person', payload);
}

export const PutPerson = (id, payload) => {
    return AuthHelper.putResponse(`/person/${id}`, payload);
}

export const DeletePerson = (id) => {
    return AuthHelper.deleteResponse(`/person/${id}`);
}