import { useState } from 'react';
import { Table, Input } from 'antd';
import { Link, useLocation } from "react-router-dom";

export const TableComponent = ({ data }) => {
    const loaction = useLocation();
    const [dataSource, setDataSource] = useState(data);
    const [value, setValue] = useState("");

    const FilterByNameInput = (
        <Input placeholder="Search Name" value={value}
            onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                const filteredData = data.filter(entry =>
                    entry.name.includes(currValue)
                );
                setDataSource(filteredData);
            }}
        />
    );

    const columns = [
        {
            title: 'PatientId',
            dataIndex: 'patient_identifier',
            key: 'patient_identifier',
            render: (text, record) => <Link to={`/visitsummary/${record.key}`} state={loaction.state} >{text}</Link>,
        },
        // {
        //     title: 'Abha No.',
        //     dataIndex: 'abha_no',
        //     key: 'abha_no',
        // },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'D.O.B',
            dataIndex: 'dob',
            key: 'dob',
            render: (text) => new Date(text).toLocaleDateString('en-US')
        },
        {
            title: 'Location',
            key: 'location',
            dataIndex: 'location',
        },
        {
            title: 'Provider',
            key: 'provider',
            dataIndex: 'provider'
        },
    ];

    return (
        <Table columns={columns} dataSource={dataSource} />
    )
}