import { Card } from "antd";
import { IdcardOutlined } from '@ant-design/icons';

const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const TVLPatientInformation = ({ data }) => {

    return (
        <div>
            <Card title={(<>
                <IdcardOutlined /> Patient Information
            </>
            )}>
                <div style={{ display: 'flex', justifyContent: 'start', gap: 20 }}>
                    <div>
                        <b>Name:</b> {`${data.person.first_name} ${data.person.middle_name || ''} ${data.person.last_name}`}
                    </div>
                    <div>
                        <b>Age:</b> {calculateAge(data.person.birthdate)}
                    </div>
                    <div>
                        <b>Sex:</b> {data.person.gender}
                    </div>
                    <div>
                        <b>Visilant_Id:</b> {data.patient_identifier}
                    </div>
                </div>

            </Card>
        </div>
    )
}