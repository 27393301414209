import * as AuthHelper from './APIHelper';

export const GetLocation = () => {
    return AuthHelper.getResponse('/location');
}

export const GetLocationWithParams = (params) => {
    return AuthHelper.getResponse(`/location?${params}`);
}

export const PostLocation = (payload) => {
    return AuthHelper.postResponse('/location', payload);
}

export const PutLocation = (id, payload) => {
    return AuthHelper.putResponse(`/location/${id}`, payload);
}

export const DeleteLocation = (id) => {
    return AuthHelper.deleteResponse(`/location/${id}`);
}