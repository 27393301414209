import { useEffect, useState, useRef } from "react";
import { Services } from '../../__services';
import { CONSTANT, Server } from '../../Utils';
import { SpinnerComponent } from "../../Component/Common";
import { processData } from './functionHandler';
import { useLocation } from "react-router-dom";
import { Button } from 'antd';
import { UserComponent } from "./User";
import { CoordinatorComponent } from "./Coordinator";
import { TVLComponent } from "./TVL";


export const HomePage = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [awaitingConsult, setAwaitingConsult] = useState(undefined);
    const [visitInProgress, setVisitInProgress] = useState(undefined);
    const [visitComplete, setVisitComplete] = useState(undefined);
    const [visitMicrobiology, setVisitMicrobiology] = useState(undefined);
    const [visitMicrobiologyResult, setVisitMicrobiologyResults] = useState(undefined);
    const [visitMicrobiologyComplete, setVisitMicrobiologyComplete] = useState(undefined);
    const [visitHealthWorkerFollowUp, setVisitHealthWorkerFollowUp] = useState(undefined);
    const [visitBaseHospitalFollowUp, setVisitBaseHospitalFollowUp] = useState(undefined);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getALLVisits(location.state.user_type);
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const onClickHandler = (type, value) => {
        if (type === 'switchUser') {
            location.state.user_type = value;
            getALLVisits(location.state.user_type)
        }
    }

    const getALLVisits = (type = 'user') => {
        setLoading(true);
        let params = '';
        // if (type === 'user') {
        params += `visit_type_id=${CONSTANT.VISITFIRST}&customQuery=location,patient(person),encounters(creator)`;
        // } 
        // else if (type === 'coordinator') {
        //     params += `visit_type_id=${CONSTANT.VISITCOORDINATOR}&customQuery=location,encounters(creator),patient(person)`;
        // }
        Services.GetVisitWithParams(params)
            .then(async response => {
                if (response.status !== 400) {
                    let { awaitingConsult, visitInProgress, visitComplete, visitMicrobiology,
                        visitMicrobiologyResult, visitMicrobiologyComplete, visitHealthWorkerFollowUp,
                        visitBaseHospitalFollowUp } = await processData(response.rows, type);
                        console.log(awaitingConsult)
                    setAwaitingConsult(awaitingConsult);
                    setVisitInProgress(visitInProgress);
                    setVisitComplete(visitComplete);
                    setVisitMicrobiology(visitMicrobiology);
                    setVisitMicrobiologyResults(visitMicrobiologyResult);
                    setVisitMicrobiologyComplete(visitMicrobiologyComplete);
                    setVisitHealthWorkerFollowUp(visitHealthWorkerFollowUp);
                    setVisitBaseHospitalFollowUp(visitBaseHospitalFollowUp);
                    setLoading(false);
                } else {

                }
            });
    }


    return (
        <>
            {loading && location.state.user_type ?
                <SpinnerComponent /> :
                Server.ENV.type !== 'tvl' ? <div style={{ padding: '5px' }}>
                    <div style={{ padding: '0px 0px 5px' }}>
                        <Button type={location.state.user_type === 'user' ? "primary" : ''} onClick={(e) => onClickHandler('switchUser', 'user')}>Doctor Exam</Button>
                        <Button type={location.state.user_type === 'coordinator' ? "primary" : ''} onClick={(e) => onClickHandler('switchUser', 'coordinator')}>Microbiologic Results</Button>
                        {/* <Switch checkedChildren="Microbiologic Results" unCheckedChildren="Doctor Exam" checked={location.state.user_type === 'coordinator'} onChange={(e) => onChangeHandler('switchUser', e)} /> */}
                    </div>
                    {location.state.user_type === 'user' ?
                        <UserComponent awaitingConsult={awaitingConsult} visitInProgress={visitInProgress} visitComplete={visitComplete} /> :
                        <CoordinatorComponent visitMicrobiology={visitMicrobiology} visitMicrobiologyResult={visitMicrobiologyResult} visitMicrobiologyComplete={visitMicrobiologyComplete} />}
                </div> : <TVLComponent awaitingConsult={awaitingConsult} visitInProgress={visitInProgress} visitHealthWorkerFollowUp={visitHealthWorkerFollowUp} visitBaseHospitalFollowUp={visitBaseHospitalFollowUp} visitComplete={visitComplete} />}
        </>
    )
}