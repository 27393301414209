import styled from 'styled-components';

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ImageSection = styled.div`
    width: 48%
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
`;