const BUILD_ENV = 'tvl';

const allENV = {
    local: {
        baseURL: "http://localhost:3004/api/v2",
        type: 'baseHospital'
    },
    visilant: {
        baseURL: "https://devapi.visilant.org/api/v2",
        type: 'baseHospital'
    },
    pndy: {
        baseURL: "https://pndyapi.visilant.org/api/v2",
        type: 'baseHospital'
    },
    img : {
        baseURL: "https://imgapi.visilant.org/api/v2",
        type: 'baseHospital'
    },
    tvl: {
        baseURL: "https://tvlapi.visilant.org/api/v2",
        type: 'tvl'
    }
};


const ENV = allENV[BUILD_ENV];

export const Server = {
    ENV
}
