import { Button, Input } from 'antd';
import { Layout, Menu } from 'antd';
import { NavigationWrapper } from './style';
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useRef } from "react";
import { Context } from "../../Pages/ContextProvider";
import { Services } from '../../__services';

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const items = [
    {
        label: 'Home',
        key: 'home',
    },
    {
        label: 'Admin',
        key: 'admin'
    },
]


export const LayoutPage = ({ children }) => {
    const { state: { page }, updateState } = useContext(Context);
    const seachRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const onClickHandler = (type, value) => {
        if (type === 'logout') {
            updateState('user', null)
            navigate('/')
        } else if (type === 'menu') {
            if (value.key === 'home') {
                updateState('page', 'user');
                navigate('/home', { state: location.state });
            } else if (value.key === 'admin') {
                updateState('page', '/admin');
                navigate('/admin', { state: location.state })
            } else {
                console.log(value)
            }
        } else if (type === 'search') {
            searchPatient(value);
        }
    }

    const searchPatient = (value) => {
        let [first_name, last_name] = value.split(' ');
        let params = ``;
        if (first_name) {
            params += `first_name=${first_name}`;
        }
        if (last_name) {
            params += `&last_name=${last_name}`;
        }
        params += `&customQuery=patient`;
        Services.GetPersonWithParams(params).then(response => {
            console.log(response);
        })
    }

    return (
        <>
            <Layout style={{ minHeight: "100vh" }}>
                <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                    <NavigationWrapper>
                        {page !== '/admin' ? <Search placeholder="Search" size='large' style={{ width: 300 }} ref={seachRef} onSearch={(e) => onClickHandler('search', e)} enterButton /> : <div></div>}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
                            <Menu theme="dark" mode="horizontal" items={items} onClick={(e) => onClickHandler('menu', e)} />
                            <Button type="primary" danger onClick={() => onClickHandler('logout')}>Logout</Button>
                        </div>
                    </NavigationWrapper>
                </Header>
                <Content>
                    <div style={{ padding: page !== '/admin' ? '24px 100px' : '' }}>
                        {children}
                    </div>
                </Content>
                {page !== '/admin' ?
                    <Footer style={{ textAlign: 'center', position: "sticky", bottom: "0" }}>Visilant ©2023</Footer>
                    : null}
            </Layout>
        </>
    )
}