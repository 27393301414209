import { useState, useEffect, useRef } from "react";
import { Select, notification, Button } from "antd";
import { ModalComponent } from '../../../../Component/Common';
import { Services } from "../../../../__services";
import { AddEditKey } from './AddEdit';
import { Protocol } from "./ProtocolDetails";

export const LicenceKey = ({ visualAcuity = false, onChange }) => {
    const [allKeys, setAllKeys] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [valueEdit, setValueEdit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [licenceName, setLicenceName] = useState();
    const [licenceDescription, setLicenceDescription] = useState();
    const [selectedLicenceKey, setSelectedLicenceKey] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllKeys();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickHandler = (type, value) => {
        if (type === 'addKey') {
            setModalTitle('Create Program key');
            setShowModal(true);
        } else if (type === 'edit') {
            setModalTitle('Edit Key');
            setShowModal(true);
            setValueEdit(value);
        } else if (type === 'delete') {
            setModalTitle('Are you Sure');
            setShowModal(true);
            setValueEdit(value);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'key') {
            setSelectedLicenceKey(value);
            if (visualAcuity) {
                onChange(type, allKeys.filter(key => key.id === value)[0])
            }
        } else if (type === 'name') {
            setLicenceName(value);
        } else if (type === 'description') {
            setLicenceDescription(value);
        }
    }

    const getAllKeys = async () => {
        try {
            // let params = `take=10&skip=${(value.current - 1) * 10}`;
            let keys = await Services.GetKey();
            let newKey = [];
            keys.rows.forEach(key => {
                newKey.push({
                    id: key.id,
                    name: key.name,
                    description: key.description,
                    licence_key_details: key?.licence_key_details,
                    modified_at: key.updated_at ? new Date(key.updated_at).toISOString().slice(0, 10) : new Date(key.created_at).toISOString().slice(0, 10)
                })
            })
            setAllKeys(newKey);
        } catch (err) {
            console.log('Fetch Key Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const saveKey = async () => {
        try {
            if (valueEdit) {
                if (modalTitle === 'Edit Key') {
                    editKey();
                } else {
                    deleteKey();
                }
                return;
            }
            if (licenceName) {
                setLoading(true);
                let payload = {
                    name: licenceName,
                    description: licenceDescription
                }
                let saveKey = await Services.PostKey(payload);
                if (saveKey.id) {
                    setTimeout(() => {
                        setShowModal(false);
                        setLoading(false);
                        resetFields();
                    }, 2000)
                    openNotificationWithIcon('success', 'Key created Successfully');
                    getAllKeys();
                } else {
                    setLoading(false);
                    openNotificationWithIcon('error', 'Data not saved');
                }
            } else {
                setTimeout(() => {
                    openNotificationWithIcon('error', 'Enter all details');
                    setLoading(false);
                }, 1000)
            }
        } catch (err) {
            console.log('Save Key Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const editKey = async () => {
        try {
            setLoading(true);
            let payload = {

            }
            let editKey = await Services.PutKey(valueEdit.id, payload);
            if (editKey.id) {
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    resetFields();
                }, 2000)
                openNotificationWithIcon('success', 'Saved Successfully');
                getAllKeys();
            } else {
                openNotificationWithIcon('error', 'Data not saved');
            }
        } catch (err) {
            console.log('Save Key Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const deleteKey = async () => {
        try {
            setLoading(true);
            let deleteKey = await Services.DeleteKey(valueEdit.id);
            if (deleteKey) {
                openNotificationWithIcon('success', 'Deleted Successfully');
                getAllKeys();
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    resetFields();
                }, 2000)
            }
        } catch (err) {
            console.log('Delete Key Exception :', err);
            openNotificationWithIcon('error', 'Data not deleted');
        }
    }

    const resetFields = () => {
        setModalTitle();
        setValueEdit();
        setLoading(false);
        setLicenceName();
        setLicenceDescription();
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select placeholder="Select a program key" onChange={(e) => onChangeHandler('key', e)} options={allKeys.map(dt => { return { key: dt.id, value: dt.id, label: dt.name } })} />
                <Button type="primary" onClick={() => onClickHandler('addKey')}>Create New Key</Button>
            </div>
            <br />
            <br />
            {!visualAcuity && selectedLicenceKey ? <Protocol selectedLicenceKey={selectedLicenceKey} /> : null}
            {showModal ?
                <ModalComponent
                    open={showModal}
                    title={modalTitle}
                    loading={loading}
                    saveData={saveKey}
                    onCancel={() => { setLoading(false); resetFields(); setShowModal(false) }}>
                    {modalTitle !== 'Are you Sure' ? <AddEditKey
                        onChange={onChangeHandler}
                        name={licenceName}
                        description={licenceDescription}
                        protocol={false}
                    /> : <p>This will delete the key "{valueEdit.name}" permanently from the system.</p>}
                </ModalComponent>
                : null}
            {contextHolder}
        </div>
    )
}