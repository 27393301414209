import * as AuthHelper from './APIHelper';

export const GetDataType = () => {
    return AuthHelper.getResponse('/datatype');
}

export const GetDataTypeWithParams = (params) => {
    return AuthHelper.getResponse(`/datatype?${params}`);
}

export const PostDataType = (payload) => {
    return AuthHelper.postResponse('/datatype', payload);
}

export const PutDataType = (id, payload) => {
    return AuthHelper.putResponse(`/datatype/${id}`, payload);
}

export const DeleteDataType = (id) => {
    return AuthHelper.deleteResponse(`/datatype/${id}`);
}