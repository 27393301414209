import { Collapse, theme, Card } from 'antd';
import { TableComponent } from '../../../Component/Common';
import { CaretRightOutlined } from '@ant-design/icons';
import { BadgeWrapper, CardData, Badge } from './style';

const { Panel } = Collapse;

export const TVLComponent = ({ awaitingConsult, visitInProgress, visitHealthWorkerFollowUp, visitBaseHospitalFollowUp, visitComplete }) => {
    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none'
    };

    return (
        <>
            <BadgeWrapper>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Awaiting Doctor Review</div>
                        <Badge color='#faad14'>{awaitingConsult.length + visitInProgress.length}</Badge>
                    </CardData>

                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Awaiting Health Worker Follow Up</div>
                        <Badge color='#0096FF'>{visitHealthWorkerFollowUp.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Base Hospital Follow Up</div>
                        <Badge color='#0096FF'>{visitBaseHospitalFollowUp.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Visit Complete</div>
                        <Badge color='green'>{visitComplete.length}</Badge>
                    </CardData>
                </Card>
            </BadgeWrapper>
            <Collapse
                bordered={false}
                size='large'
                defaultActiveKey={['1', '2', '3', '4']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel header="Awaiting Doctor Review" key="1" style={panelStyle}>
                    {awaitingConsult?.length ?
                        <TableComponent data={[...visitInProgress, ...awaitingConsult]} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header="Awaiting Health Worker Follow Up" key="2" style={panelStyle}>
                    {visitHealthWorkerFollowUp?.length ?
                        <TableComponent data={visitHealthWorkerFollowUp} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header="Base Hospital Follow Up" key="3" style={panelStyle}>
                    {visitBaseHospitalFollowUp?.length ?
                        <TableComponent data={visitBaseHospitalFollowUp} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header="Visit Complete" key="4" style={panelStyle}>
                    {visitComplete?.length ?
                        <TableComponent data={visitComplete} /> :
                        <p>No Data</p>
                    }
                </Panel>
            </Collapse>
        </>
    )
}