import { TVLPatientInformation } from './PatientInformation';
import { TVLOnExamination } from './OnExamination';
import { TVLReferral } from './Referral';

import { Button } from 'antd';
import { TVLDiagnosis } from './Diagnosis';
import { TVLMedicalHistory } from './MedicalHistory';
import { TVLDoctorNote } from './DoctorsNote';

export const TVLVisitSummary = ({ adultinitial, visitInProgress, onChangeHandler, onClickHandler, submitDiagnosis }) => {

    return (
        <>
            <div style={{ fontSize: 30, textAlign: 'center', paddingBottom: 10 }}>Ophthalmologist Diagnosis</div>
            <TVLPatientInformation data={adultinitial.patient} />
            <br />
            <TVLOnExamination data={adultinitial} />
            <br />
            <TVLDiagnosis data={visitInProgress} submit={submitDiagnosis} />
            <br />
            <TVLMedicalHistory data={visitInProgress} />
            <br />
            <TVLReferral data={visitInProgress} submit={submitDiagnosis} onChange={onChangeHandler} />
            <br />
            <TVLDoctorNote data={visitInProgress} submit={submitDiagnosis} />
            <br />
            <div style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => onClickHandler('tvl-submit')}>Submit</Button>
            </div>
        </>
    )
}