import { Modal } from 'antd';

export const ModalComponent = ({open, title, loading, children, saveData, onCancel}) => {
    return (
        <>
            <Modal
                title={title}
                onOk={saveData}
                open={open}
                confirmLoading={loading}
                onCancel={onCancel}
            >
                {children}
            </Modal>
        </>
    );
};