import * as AuthHelper from './APIHelper';

export const GetKey = () => {
    return AuthHelper.getResponse('/licencekey');
}

export const GetKeyWithParams = (params) => {
    return AuthHelper.getResponse(`/licencekey?${params}`);
}

export const PostKey = (payload) => {
    return AuthHelper.postResponse('/licencekey', payload);
}

export const PutKey = (id, payload) => {
    return AuthHelper.putResponse(`/licencekey/${id}`, payload);
}

export const DeleteKey = (id) => {
    return AuthHelper.deleteResponse(`/licencekey/${id}`);
}