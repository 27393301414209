import * as AuthHelper from './APIHelper';

export const GetVisit = () => {
    return AuthHelper.getResponse('/visit');
}

export const GetVisitWithParams = (params) => {
    return AuthHelper.getResponse(`/visit?${params}`);
}

export const PostVisit = (payload) => {
    return AuthHelper.postResponse('/visit', payload);
}

export const PutVisit = (id, payload) => {
    return AuthHelper.putResponse(`/visit/${id}`, payload);
}

export const DeleteVisit = (id) => {
    return AuthHelper.deleteResponse(`/visit/${id}`);
}