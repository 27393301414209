import styled from "styled-components";

export const BadgeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 5px 5px 15px;
`;

export const CardData = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center
`;

export const Badge = styled.div`
    background: ${p => p.color};
    border-radius: 35%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`;