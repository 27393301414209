import * as AuthHelper from './APIHelper';

export const GetPersonType = () => {
    return AuthHelper.getResponse('/persontype');
}

export const GetPersonTypeWithParams = (params) => {
    return AuthHelper.getResponse(`/persontype?${params}`);
}

export const PostPersonType = (payload) => {
    return AuthHelper.postResponse('/persontype', payload);
}

export const PutPersonType = (id, payload) => {
    return AuthHelper.putResponse(`/persontype/${id}`, payload);
}

export const DeletePersonType = (id) => {
    return AuthHelper.deleteResponse(`/persontype/${id}`);
}