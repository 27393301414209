import { useState } from "react";
import { Button, notification, Switch } from "antd";
import { Services } from "../../../../__services";
import { LicenceKey } from "../Protocol";
import { SwitchWrapper } from './style';

const allVisualAcuity = ['6/6', '6/9', '6/12', '6/18', '6/36', '6/60', 'Finger Counts', 'Hand movements', 'Light perception', 'No light perception'];

export const VisualAcuity = () => {
    const [allAcuity, setAllAcuity] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [selectedLicenceKey, setSelectedLicenceKey] = useState();

    const onClickHandler = (type) => {
        if (type === 'apply') {
            saveAcuity();
        }
    }

    const onChangeHandler = (type, value, addValue) => {
        if (type === 'key') {
            setSelectedLicenceKey(value);
            setAllAcuity(value?.licence_key_details.acuity_cut_off)
        } else if (type === 'switch') {
            let newValue = [...allAcuity];
            if (value) {
                newValue.push(addValue);
            } else {
                let index = newValue.indexOf(addValue);
                if (index !== -1) {
                    newValue.splice(index, 1);
                }
            }
            setAllAcuity(newValue);
        }
    }

    const saveAcuity = async () => {
        try {
            if (!selectedLicenceKey) {
                openNotificationWithIcon('error', 'Key Not Selected');
                return;
            }
            let payload = {
                name: selectedLicenceKey.name,
                licence_key_details: {
                    acuity_cut_off : allAcuity
                }
            }
            let save = await Services.PutKey(selectedLicenceKey.id, payload);
            if (save.modified_by) {
                openNotificationWithIcon('success', 'Acuity Cutoff saved successfully');
            } else {
                openNotificationWithIcon('error', 'Data not saved');
            }
        } catch(err) {
            console.log('Save Acuity Exception :', err);
        }
    }


    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    return (
        <div>
            <div>Set Cutoffs for visual acuity screening</div>
            <br />
            <LicenceKey visualAcuity={true} onChange={onChangeHandler} />
            {allVisualAcuity.map((ac, index) =>
                <SwitchWrapper key={index}>
                    <Switch checked={allAcuity.includes(ac)} onChange={(checked) => onChangeHandler('switch', checked, ac)}/>
                    <div>{ac}</div>
                </SwitchWrapper>
            )}
            <br/>
            <br/>
            <div style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={() => onClickHandler('apply')}>Apply</Button>
            </div>
            {contextHolder}
        </div>
    )
}