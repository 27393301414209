import * as AuthHelper from './APIHelper';

export const GetImage = () => {
    return AuthHelper.getResponse('/image');
}

export const GetImageWithParams = (params) => {
    return AuthHelper.getResponse(`/image?${params}`);
}

export const PostImage = (payload) => {
    return AuthHelper.postResponse('/image', payload);
}

export const PutImage = (id, payload) => {
    return AuthHelper.putResponse(`/image/${id}`, payload);
}

export const DeleteImage = (id) => {
    return AuthHelper.deleteResponse(`/image/${id}`);
}