
import styled from "styled-components";
import { Spin } from "antd";

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const SpinnerComponent = () => {
    return (
        <SpinnerWrapper>
            <Spin tip="Loading" size="large">
                <div className="content" />
            </Spin>
        </SpinnerWrapper>
    )
}