import { Card, Radio, Space, AutoComplete, Divider, notification, Alert, Checkbox } from "antd";
import { DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { DiagnosisWrapper, DiagnosisSection } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

const diagnosisList = ['Active corneal infection', 'Inactive corneal opacity', 'Epithelial Defect', 'Corneal Abrasion', 'Pterygium'];
const lensDiagnosisList = ['Mature cataract', 'Immature cataract', 'Lens Changes', 'Clear Crystalline lens', 'PCIOL', 'Aphakia', 'Corneal opacity prevents diagnosis of lens status'];
const list = [
    { value: 'Pterygium' }, { value: 'Conjunctivitis' },
    { value: 'Subconjunctival hemorrhage' }, { value: 'Presbyopia' },
    { value: 'Active Corneal Infection' }, { value: 'Posterior Segment Screening' },
    { value: 'Cannot be assessed' }
];

// const cataractTypeList = ['NS', 'PCS', 'CS', 'n/a'];
// const cataractGradeList = ['1', '2', '2+', '3', '3+', '4', 'n/a'];

export const TVLDiagnosis = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();

    const [rightEyeLensDiagnosis, setRightEyeLensDiagnosis] = useState([]);
    const [leftEyeLensDiagnosis, setLeftEyeLensDiagnosis] = useState([]);

    const [rightEyePathalogyDiagnosis, setRightEyePathalogyDiagnosis] = useState([]);
    const [leftEyePathalogyDiagnosis, setLeftEyePathalogyDiagnosis] = useState([]);

    const [rightEyeLens, setRightEyeLens] = useState();
    const [leftEyeLens, setLeftEyeLens] = useState();

    const [rightEyeAdditionalPathology, setRightEyeAdditionalPathology] = useState([]);
    const [leftEyeAdditionalPathology, setLeftEyeAdditionalPathology] = useState([]);



    const [rightEyeInput, setRightEyeInput] = useState();
    const [leftEyeInput, setLeftEyeInput] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getDiagnosis();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitDiagnosis();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteDiagnosis(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'rightEyeLens') {
            setRightEyeLens(value);
        } else if (type === 'leftEyeLens') {
            setLeftEyeLens(value);
        } else if (type === 'rightEyeAdditionalPathology') {
            setRightEyeAdditionalPathology(value);
        } else if (type === 'leftEyeAdditionalPathology') {
            setLeftEyeAdditionalPathology(value);
        }
    }

    const getDiagnosis = async () => {
        setRightEyeLensDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS));
        setLeftEyeLensDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS));
        setRightEyePathalogyDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY));
        setLeftEyePathalogyDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY));
    }

    const submitDiagnosis = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (rightEyeLens) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISRIGHTEYELENS,
                obs_string: {
                    value: rightEyeLens
                }
            })
        } else if (!rightEyeLensDiagnosis.length) {
            openNotificationWithIcon('error', 'Select the Right Eye Lens Status');
            return;
        }
        if (leftEyeLens) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISLEFTEYELENS,
                obs_string: {
                    value: leftEyeLens
                }
            })
        } else if (!leftEyeLensDiagnosis.length) {
            openNotificationWithIcon('error', 'Select the Left Eye Lens Status');
            return;
        }
        if (rightEyeAdditionalPathology.length) {
            let rightEyePathValue = [...rightEyeAdditionalPathology], processedString = '';
            let checkInput = rightEyePathValue.indexOf(4);
            if (checkInput !== -1) {
                processedString += `${rightEyeInput}, `;
                rightEyePathValue.splice(checkInput, 1);
            }
            processedString += rightEyePathValue.join(', ');
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY,
                obs_string: {
                    value: processedString
                }
            })
        }
        if (leftEyeAdditionalPathology.length) {
            let leftEyePathValue = [...leftEyeAdditionalPathology], processedString = '';
            let checkInput = leftEyePathValue.indexOf(4);
            if (checkInput !== -1) {
                processedString += `${leftEyeInput}, `;
                leftEyePathValue.splice(checkInput, 1);
            }
            processedString += leftEyePathValue.join(', ');
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY,
                obs_string: {
                    value: processedString
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let rightEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS);
                    let leftEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS);
                    let rightEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY);
                    let leftEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY);
                    if (rightEyeL.length) {
                        setRightEyeLensDiagnosis([...rightEyeLensDiagnosis, ...rightEyeL]);
                    }
                    if (leftEyeL.length) {
                        setLeftEyeLensDiagnosis([...leftEyeLensDiagnosis, ...leftEyeL]);
                    }
                    if (rightEyeD.length) {
                        setRightEyePathalogyDiagnosis([...rightEyePathalogyDiagnosis, ...rightEyeD]);
                    }
                    if (leftEyeD.length) {
                        setLeftEyePathalogyDiagnosis([...leftEyePathalogyDiagnosis, ...leftEyeD]);
                    }
                    resetField();
                    openNotificationWithIcon('success', 'Diagnosis Submitted', '');
                }
            })
        } else {
  
        }
    }

    const deleteDiagnosis = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'rightEyeLensDiagnosis') {
                    let newData = [...rightEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyeLensDiagnosis(newData);
                    }
                } else if (path === 'leftEyeLensDiagnosis') {
                    let newData = [...leftEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyeLensDiagnosis(newData);
                    }
                } else if (path === 'rightEyePathalogyDiagnosis') {
                    let newData = [...rightEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyePathalogyDiagnosis(newData);
                    }
                } else if (path === 'leftEyePathalogyDiagnosis') {
                    let newData = [...leftEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyePathalogyDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'Diagnosis Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setRightEyeLens('');
        setLeftEyeLens('')
        setRightEyeAdditionalPathology([]);
        setRightEyeInput('');
        setLeftEyeAdditionalPathology([]);
        setLeftEyeInput('');
    }

    return (
        <>
            <Card title={(<> <EyeOutlined /> Physician Diagnosis</>)}>
                <DiagnosisWrapper>
                    <div style={{ width: '100%' }}>
                        <div>Right Eye</div>
                        <Divider />
                        <DiagnosisSection>
                            <div style={{ width: '75%' }}>
                                <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4>
                                <Radio.Group value={rightEyeLens} onChange={(e) => onChangeHandler('rightEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div style={{ width: '100%' }}>
                                <h4 style={{ marginTop: 0 }}>Additional Pathology</h4>
                                <Checkbox.Group onChange={(e) => onChangeHandler('rightEyeAdditionalPathology', e)} value={rightEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        <Checkbox value={4}>Other</Checkbox>
                                        {rightEyeAdditionalPathology.includes(4) ?
                                            <AutoComplete
                                                options={list}
                                                value={rightEyeInput} onChange={e => setRightEyeInput(e)}
                                                style={{ width: 200, marginLeft: 10 }}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                            </AutoComplete> : null}
                                    </Space>
                                </Checkbox.Group>
                            </div>

                        </DiagnosisSection>
                        {rightEyeLensDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                        {rightEyePathalogyDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        <div>Left Eye</div>
                        <Divider />
                        <DiagnosisSection>
                        <div style={{ width: '75%' }}>
                                <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4>
                                <Radio.Group value={leftEyeLens} onChange={(e) => onChangeHandler('leftEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div style={{ width: '100%' }}>
                                <h4 style={{ marginTop: 0 }}>Additional Pathology</h4>
                                <Checkbox.Group onChange={(e) => onChangeHandler('leftEyeAdditionalPathology', e)} value={leftEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        <Checkbox value={4}>Other</Checkbox>
                                        {leftEyeAdditionalPathology.includes(4) ?
                                            <AutoComplete
                                                options={list}
                                                value={leftEyeInput} onChange={e => setLeftEyeInput(e)}
                                                style={{ width: 200, marginLeft: 10 }}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                            </AutoComplete> : null}
                                    </Space>
                                </Checkbox.Group>
                            </div>
                        </DiagnosisSection>

                        {leftEyeLensDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>)}
                        {leftEyePathalogyDiagnosis.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>
                </DiagnosisWrapper>
            </Card>
            {contextHolder}
        </>
    )
}