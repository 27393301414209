import { useState, useEffect, useRef } from "react";
import { notification, Button } from "antd";
import { TableSort, ModalComponent } from '../../../../Component/Common';
import { Services } from "../../../../__services";
import { AddEditKey } from './AddEdit';

const columns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Last Update', dataIndex: 'modified_at' },
]

export const Protocol = ({selectedLicenceKey}) => {
    let fileReader;
    const [allKeysProtocols, setAllKeysProtocols] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [valueEdit, setValueEdit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [protocolName, setProtocolName] = useState();
    const [protocolDescription, setProtocolDescription] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 10
    })
    const [tableLoading, setTableLoading] = useState(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllKeysProtocols();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickHandler = (type, value) => {
        if (type === 'addProtocol') {
            setModalTitle('Create Program protocol');
            setShowModal(true);
        } else if (type === 'edit') {
            setModalTitle('Edit Key Protocol');
            setShowModal(true);
            setProtocolName(value.name);
            setProtocolDescription(value.licence_key_protocol_details);
            setValueEdit(value);
        } else if (type === 'delete') {
            setModalTitle('Are you Sure');
            setShowModal(true);
            setValueEdit(value);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'file') {
            fileReader = new FileReader();
            fileReader.onload = e => {
                const content = e.target.result;
                setProtocolName(value.name);
                setProtocolDescription(content);
            }
            fileReader.readAsText(value.originFileObj);
        } else if (type === 'description') {
            setProtocolDescription(value);
        } else if (type === 'table') {
            getAllKeysProtocols(value);
        }
    }

    const getAllKeysProtocols = async (value = pagination) => {
        try {
            setTableLoading(true);
            let params = `take=10&skip=${(value.current - 1) * 10}&licence_key_id=${selectedLicenceKey}`;
            let protocols = await Services.GetProtocolWithParams(params);
            let newprotocols = [];
            protocols.rows.forEach(protocol => {
                newprotocols.push({
                    id: protocol.id,
                    name: protocol.name,
                    licence_key_protocol_details: JSON.stringify(protocol.licence_key_protocol_details),
                    modified_at: protocol.updated_at ? new Date(protocol.updated_at).toISOString().slice(0, 10) : new Date(protocol.created_at).toISOString().slice(0, 10)
                })
            })
            setAllKeysProtocols(newprotocols);
            setPagination({
                ...pagination,
                ...value,
                total: protocols.total
            });
            setTableLoading(false);
        } catch (err) {
            console.log('Fetch Key Protocol Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
            setTableLoading(false);
        }
    }

    const saveKey = async () => {
        try {
            if (valueEdit) {
                if (modalTitle === 'Edit Key Protocol') {
                    editKey();
                } else {
                    deleteKey();
                }
                return;
            }
            if (protocolDescription) {
                setLoading(true);
                let payload = {
                    licence_key_id: selectedLicenceKey,
                    name: protocolName,
                    licence_key_protocol_details: JSON.parse(protocolDescription)
                }
                let saveKey = await Services.PostProtocol(payload);
                if (saveKey.id) {
                    setTimeout(() => {
                        setShowModal(false);
                        setLoading(false);
                        resetFields();
                    }, 2000)
                    openNotificationWithIcon('success', 'KeyProtocol created Successfully');
                    getAllKeysProtocols();
                } else {
                    setLoading(false);
                    openNotificationWithIcon('error', 'Data not saved');
                }
            } else {
                setTimeout(() => {
                    openNotificationWithIcon('error', 'Enter all details');
                    setLoading(false);
                }, 1000)
            }
        } catch (err) {
            console.log('Save KeyProtocol Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const editKey = async () => {
        try {
            setLoading(true);
            let payload = {
                name: protocolName,
                licence_key_protocol_details: JSON.parse(protocolDescription)
            }
            let editKey = await Services.PutProtocol(valueEdit.id, payload);
            if (editKey.id) {
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    setValueEdit(undefined);
                    resetFields();
                }, 2000)
                openNotificationWithIcon('success', 'Saved Successfully');
                getAllKeysProtocols();
            } else {
                openNotificationWithIcon('error', 'Data not saved');
            }
        } catch (err) {
            console.log('Save KeyProtocol Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const deleteKey = async () => {
        try {
            setLoading(true);
            let deleteKey = await Services.DeleteProtocol(valueEdit.id);
            if (deleteKey) {
                openNotificationWithIcon('success', 'Deleted Successfully');
                getAllKeysProtocols();
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    setValueEdit(undefined);
                }, 2000)
            }
        } catch (err) {
            console.log('Delete KeyProtocol Exception :', err);
            openNotificationWithIcon('error', 'Data not deleted');
        }
    }

    const resetFields = () => {
        setProtocolName();
        setProtocolDescription();
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    return (
        <div>
            <TableSort data={allKeysProtocols} pagination={pagination} onChange={onChangeHandler} onClick={onClickHandler} columns={columns} loading={tableLoading} />
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
                <Button type="primary" onClick={() => onClickHandler('addProtocol')}>Upload Protocol</Button>
            </div>
            {showModal ?
                <ModalComponent
                    open={showModal}
                    title={modalTitle}
                    loading={loading}
                    saveData={saveKey}
                    onCancel={() => { setLoading(false); resetFields(); setShowModal(false) }}>
                    {modalTitle !== 'Are you Sure' ? <AddEditKey
                        onChange={onChangeHandler}
                        name={protocolName}
                        description={protocolDescription}
                        protocol={true}
                    /> : <p>This will delete the protocol "{valueEdit.name}" permanently from the system.</p>}
                </ModalComponent>
                : null}
            {contextHolder}
        </div>
    )
}