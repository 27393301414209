import { Alert, AutoComplete, Button, Card, Input, notification } from "antd";
import { DeleteFilled } from '@ant-design/icons'
import { useRef, useEffect, useState } from "react";
import { MedicalWrapper } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

const conceptPrescription = [
    { value: 'Moxifloxacin 0.5% drops' }, { value: 'Gatifloxacin 0.3% drops' }, { value: 'Azithromycin 1% drops' }, { value: 'Azithromycin 1% ointment' },
    { value: 'Aminogen 1% drops' }, { value: 'Tobramycin 0.3% drops' }, { value: 'Tobramycin 0.3% ointment' }, { value: 'Natamycin 5% drops' }, { value: 'Voriconazole 1% drops' }, { value: 'Amphotericin-B 0.5% drops' },
    { value: 'Fluconazole 0.3% drops' }, { value: 'Itraconazole 1% ointment' }, { value: 'Econazole 2% drops' }, { value: 'Homatropine Hydrobromide 2% drops' }, { value: 'Atropine 1% drops' }, { value: 'Iotim, Iobet 0.5% drops' },
    { value: 'Paracetamol 650 mg' }, { value: 'Diclofenac 75 mg' }, { value: 'Diamox 250mg' }
];

const frequenceyList = [{value : "Hourly"}, {value : "Once daily"}, {value : "Twice daily"}, {value : "Three times daily"}, {value : "Four times daily"}, {value : "Every 2 hours"}, {value : "Every 4 hours"}, {value : "Every 8 hours"}, {value : "Every other day"}];

export const MedicalHistory = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();
    const [allMedical, setAllMedical] = useState([]);
    const [days, setDays] = useState('');
    const [preDays, setPreDays] = useState('');
    const [eye, setEye] = useState('');
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getMedicalHistory();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            // submitMedicalHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const onClickHandler = (type, value) => {
        if (type === 'deleteMedical') {
            deleteMedical(value);
        } else if (type === 'addMedication') {
            submitMedicalHistory()
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getMedicalHistory = async () => {
        setAllMedical(data.obs.filter(ob => ob.concept_id === CONSTANT.MEDICALHISTORY));
    }

    const submitMedicalHistory = async () => {
        let user = await getFromLocalStorage('user');
        if (preDays && days && eye) {
            let payload = {
                encounter_id: data.id,
                creator_id: user.user.person_id,
                concept_id: CONSTANT.MEDICALHISTORY,
                patient_id: data.patient_id,
                visit_id: data.visit_id,
                obs_string: {
                    value: `${preDays} - ${days} days, ${eye} Eye`
                }
            }
            Services.PostObs(payload).then(response => {
                if (response.status !== 400) {
                    resetField();
                    setAllMedical([...allMedical, response])
                    openNotificationWithIcon('success', 'Medical History Submitted', '');
                }
            })
        }
    }

    const deleteMedical = (id) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                let index = allMedical.findIndex(dt => dt.id === id);
                if (index !== -1) {
                    let newData = [...allMedical]
                    newData.splice(index, 1);
                    setAllMedical(newData);
                    openNotificationWithIcon('success', 'Medical History Deleted');
                }
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setDays('');
        setPreDays('');
        setEye('');
    }

    return (
        <div>
            <Card title={(<>
                Medical History
            </>
            )}>
                <MedicalWrapper>
                    <div>
                        <div style={{ marginLeft: 10 }}>Topical Steroid Used:</div>
                        <AutoComplete options={conceptPrescription}
                            style={{ width: 200, marginLeft: 10 }}
                            value={preDays} onChange={(e) => setPreDays(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                    </div>
                    <div>
                        <div>Number of days used: </div>
                        <Input type="number" min="1" placeholder="Days" value={days} onChange={(e) => setDays(e.target.value)} />
                    </div>
                    <div>
                        <div style={{ marginLeft: 10 }}>Frequency:</div>
                        <AutoComplete options={frequenceyList}
                            style={{ width: 200, marginLeft: 10 }}
                            value={eye} onChange={(e) => setEye(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                    </div>
                    <Button type="primary" onClick={() => onClickHandler('addMedication')}>Add Med</Button>
                </MedicalWrapper>
                {allMedical.map(medical => <div key={medical.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{medical.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteMedical', medical.id)} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
            </Card>
            {contextHolder}
        </div>
    )
}