import { PatientInformation } from './PatientInformation';
import { MedicalHistory } from './MedicalHistory';
import { InfectionHistory } from './InfectionHistory';
import { Diagnosis } from './Diagnosis';
import { EMR } from './EMR';
import { OnExamination } from './OnExamination';
import { SlitLampFinding } from './SlitLampFinding';
import { Button } from 'antd';

export const UserVisitSummary = ({ adultinitial, visitInProgress, showInfectionFragment, showMedicationFragment, onChangeHandler, onClickHandler, submitDiagnosis, submitInfectionHistory, submitMedicalHistory }) => {

    return (
        <>
            <div style={{ fontSize: 30, textAlign: 'center', paddingBottom: 10 }}>Ophthalmologist Diagnosis</div>
            <PatientInformation data={adultinitial.patient} />
            <br />
            <OnExamination data={adultinitial} />
            <br />
            <Diagnosis data={visitInProgress} submit={submitDiagnosis} onChange={onChangeHandler} />
            <br />
            <EMR data={visitInProgress} submit={submitDiagnosis} onChange={onChangeHandler}/>
            <br/>
            <SlitLampFinding data={visitInProgress} submit={submitDiagnosis} />
            {showInfectionFragment ? <><br />
                <InfectionHistory data={visitInProgress} submit={submitInfectionHistory} onChange={onChangeHandler} /></> : null}
            {showMedicationFragment ? <><br />
                <MedicalHistory data={visitInProgress} submit={submitMedicalHistory} /></> : null}
            <br />
            <div style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => onClickHandler('visitComplete-diagnosis')}>Submit</Button>
            </div>
        </>
    )
}