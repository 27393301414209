import { Services } from "../__services";
import { getFromLocalStorage, saveToLocalStorage } from "./useLocalStorage";

/**
 * Get token from storage
 * @returns promise
 */
export const getToken = async () => {
	return await getFromLocalStorage('web_token');
}

/**
 * Set token to localstorage
 * @param {*} token 
 * @returns 
 */
export const setToken = (token) => {
	return saveToLocalStorage('web_token', token)
}

/**
 * Check if active session is present
 * @returns 
 */
export const CheckSession = () => {
	return new Promise((resolve, reject) => {
		Services.CheckSession().then(response => {
			if (response && response.status !== 400 && response.status !== 401) {
				resolve(true)
			} else {
				saveToLocalStorage('user', null);
				reject();
			}
		}).catch(err => {
			reject();
		})
	})
}

/**
 * Constant Values used across Application
 */
export const CONSTANT = {
	SYSTEMADMIN: 'c51495dc-f968-46b0-b638-8d6a4686b7bc',
	COORDINATOR: 'ca5e61c1-38fb-4bb6-8540-f4f423d6fc32',

	VISITFIRST: 'b204531e-d28f-43e3-a9e4-43f1cc9aa1bd',
	VISITFOLLOWUP: 'f4be38d7-b156-42cf-8caf-51cadda41b2a',
	VISITCOORDINATOR: '5f6818cc-4180-41fe-8204-fb81646b228e',

	ENCOUNTERADULTINITIAL: '0484fb21-d714-4154-8898-84aea9c1d75c',
	ENCOUNTERVISITINPROGRESS: '351aa45f-ede4-4221-9368-434d1c1f63db',
	ENCOUNTERVISITCOMPLETE: '8a62ea5a-3531-4149-bb54-4a740c87767e',

	ENCOUNTERMICROBIOLOGY: 'c2216f97-a16a-4eb6-a186-c7cf4c0e9d4e',
	ENCOUNTERMICROBIOLOGYRESULTS: 'f6d98c74-65a1-4a33-a425-7d10b101a08d',
	ENCOUNTERMICROBIOLOGYCOMPLETE: '81739e9e-aebe-40cb-9fbd-94dff02a5399',

	ENOCUNTERBASEHOSPITALFOLLOWUP: '06d9318e-e1ab-445c-9f75-0ca759e54f3c',
	ENCOUNTERHEALTHWORKERFOLLOWUP: 'a6deb93c-15dc-4626-8988-581ab49d0d0c',

	COMPLAINTRIGHT: '08c18f4e-1e97-43be-a106-1b12debce911',
	COMPLAINTLEFT: '80573476-815f-412c-87de-c26f5d366c96',
	VARIGHT: '529c2adb-91d2-4916-944b-1237755a8540',
	VALEFT: 'a1ebc13a-7f43-4510-809a-135cbc1fc445',
	PINEHOLERIGHT: '096e00f0-08a7-4f22-9fc4-8341ac391825',
	PINEHOLELEFT: 'f452f28b-5578-4550-bcd7-6b2f4b06a51b',

	EMRIOPLEFT: 'ba8f4c2b-87a6-459d-ba39-a9b8597b9979',
	EMRIOPRIGHT: 'c21573fe-5e86-4262-a459-07fb79a058fd',
	EMRBLOODSUGAR: '406b0ccb-41bf-4d2d-9d39-c11bb062400e',
	EMRLACRIMALDUCT: 'e1d74cc0-91e9-4680-bef1-80984c4c16bc',

	DIAGNOSISLEFTEYELENS: '9d988280-2b4a-495d-81e3-a182f1a6f814',
	DIAGNOSISRIGHTEYELENS: '6a0f4cd0-49a7-43eb-8ceb-8e44f805b386',
	DIAGNOSISLEFTEYEADDITIONALPATHALOGY: '8b940aec-1bf9-4e65-bdfe-ec495b76483b',
	DIAGNOSISRIGHTEYEADDITIONALPATHALOGY: 'cda9995c-6ad5-48a9-847d-096361e74295',

	DIAGNOSISSLITLAMPFINDINGCORNEARIGHT: 'fe4817c3-00f0-4c01-85d9-10cf382d7150',
	DIAGNOSISSLITLAMPFINDINGCORNEALEFT: '3fcbb1be-dddd-4339-93df-5a8b0213bf49',
	DIAGNOSISSLITLAMPFINDINGLENSRIGHT: '537b305e-ded3-43e8-8001-584b7df72b7d', //'02e94e24-9e3a-4308-9f98-0973cf122d58',
	DIAGNOSISSLITLAMPFINDINGLENSLEFT: '8e140a16-8436-4d56-8906-6dcede9ca48c',
	DIAGNOSISSLITLAMPFINDINGCONJUNCTIVARIGHT: '2e42f300-7e1b-46a1-9191-5a0bf9cfc5eb',
	DIAGNOSISSLITLAMPFINDINGCONJUNCTIVALEFT: 'ba8a4523-55ae-49df-b8a6-9d6da1f60962',

	DIAGNOSISOTHERNOTERRIGHT: 'b80bfe48-9c0b-4234-9ee0-1863e57bdeee',
	DIAGNOSISOTHERNOTERLEFT: '35215b70-b3d6-4f84-b8f5-6b4734bf9d34',

	DIAGNOSISCATARACTTYPERIGHT: 'cf51c2c2-ad9c-42cb-9864-c419876e5db4',
	DIAGNOSISCATARACTTYPELEFT: 'e3e70ffc-1e4f-435a-8c9d-5d544d40b38f',
	DIAGNOSISCATARACTGRADERIGHT: '42bc78c0-8a86-4e37-b9d7-2bd776bf2749',
	DIAGNOSISCATARACTGRADELEFT: 'ae1df5a3-360a-4ff1-a6c3-c99fd2941585',

	INFECTIONHISTORYSYMPTOMSDAYS: 'f61ea259-34b2-4883-8bc1-7379765c205f',
	INFECTIONHISTORYPRESENTATIONDATE: '10c4d5ac-1592-4ae9-b6d6-25e5b338a909',
	INFECTIONHISTORYRISKFACTOR: 'db2b3587-d476-4a27-897c-130343bc93ea',

	MEDICALHISTORY: 'ff386a14-432d-403e-a13a-dffa24b5452b',

	REFERRAL: 'd27e4156-e66d-4ef2-80bb-f43eab7094aa',
	REFERRALTIME: '930c428f-7a30-469b-9b7b-092935143a28',

	DOCTORNOTES: '2f71e19d-b9c6-4b47-a6da-5ef7c900a6bb',

	COORDINATORKOHSTAIN: 'c3d71743-b1e9-432c-8f11-2ffcd83479b8',
	COORDINATORGRAMSTAIN: '9341b340-a4ce-49eb-887f-9b5a2f669bfd',
	COORDINATORBACTERIALCULTURE: '403cdc6b-7fb8-49bd-b91b-56ee45388de6',
	COORDINATORFUNGALCULTURE: '28056e08-5b9f-466f-b6c1-01ffd62b5cc6',
	COORDINATORACANTHAMOEBA: 'd25e4af9-1550-4f41-b403-efb7fd5647c6',
	COORDINATOROTHER: 'd00e8ce7-1a7d-4462-b7b0-d1bb0498986c'
}