import { CONSTANT, Server } from '../../Utils';

export const processData = (datas = [], type) => {
    let newDatas = {
        awaitingConsult: [],
        visitInProgress: [],
        visitComplete: [],
        visitMicrobiology: [],
        visitMicrobiologyResult: [],
        visitMicrobiologyComplete: [],
        visitHealthWorkerFollowUp: [],
        visitBaseHospitalFollowUp: []
    }
    return new Promise((resolve) => {
        if (datas.length) {
            datas.forEach((data, index) => {
                let { id, encounters, location, patient } = data;
                if (encounters.length) {
                    let typeOfVisit = 'awaitingConsult', encounter;
                    if (type === 'user') {
                        let VISITCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITCOMPLETE);
                        if (VISITCOMPLETE) {
                            encounter = VISITCOMPLETE;
                            typeOfVisit = 'visitComplete';
                        }
                        else {
                            let VISITINPROGRESS = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                            if (Server.ENV.type === 'baseHospital') {
                                if (VISITINPROGRESS) {
                                    encounter = VISITINPROGRESS;
                                    typeOfVisit = 'visitInProgress';
                                } else {
                                    encounter = encounters[0];
                                }
                            } else if (Server.ENV.type === 'tvl') {
                                let VISITINHEALTHFOLLOWUP = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERHEALTHWORKERFOLLOWUP);
                                let VISITINBASEFOLLOWUP = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENOCUNTERBASEHOSPITALFOLLOWUP);
                                let VISITINPROGRESS = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                                if (VISITINBASEFOLLOWUP) {
                                    encounter = VISITINBASEFOLLOWUP;
                                    typeOfVisit = 'visitBaseHospitalFollowUp';
                                } else if (VISITINHEALTHFOLLOWUP) {
                                    encounter = VISITINHEALTHFOLLOWUP;
                                    typeOfVisit = 'visitHealthWorkerFollowUp';
                                } else if (VISITINPROGRESS) {
                                    encounter = VISITINPROGRESS;
                                    typeOfVisit = 'visitInProgress';
                                } else {
                                    encounter = encounters[0];
                                }
                            }
                        }
                    } else {
                        let VISITMICROBIOLOGYCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE);
                        let VISITMICROBIOLOGYRESULT = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS);
                        let VISITMICROBIOLOGY = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGY);
                        if (VISITMICROBIOLOGYCOMPLETE) {
                            encounter = VISITMICROBIOLOGYCOMPLETE;
                            typeOfVisit = 'visitMicrobiologyComplete';
                        } else if (VISITMICROBIOLOGYRESULT) {
                            encounter = VISITMICROBIOLOGYRESULT;
                            typeOfVisit = 'visitMicrobiologyResult';
                        } else if (VISITMICROBIOLOGY) {
                            encounter = VISITMICROBIOLOGY;
                            typeOfVisit = 'visitMicrobiology';
                        }
                        
                    }
                    if (encounter) {
                        newDatas[typeOfVisit].push(
                            {
                                key: id,
                                patient_id: patient.id,
                                patient_identifier: patient.patient_identifier,
                                abha_no: patient.abha_no,
                                name: `${patient.person.first_name} ${patient.person.middle_name || ''} ${patient.person.last_name}`,
                                gender: patient.person.gender,
                                dob: patient.person.birthdate,
                                location: location.name,
                                provider: `${encounter.creator.first_name} ${encounter.creator.middle_name || ''} ${encounter.creator.last_name}`
                            }
                        )
                    }
                }
                if (index + 1 === datas.length) {
                    resolve(newDatas)
                }
            })
        } else {
            resolve(newDatas)
        }
    })
}