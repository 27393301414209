import styled from "styled-components";

export const SlitWrapper = styled.table`
    width: 100%;
`;

export const SlitRow = styled.tr`
`;
export const SlitHead = styled.th``;
    
export const SlitData = styled.td`
    // width: 33%;
`;

export const SlitDataWithCheckbox = styled.td`
    display: flex;
    gap: 1rem;
    width: 100%;
    width: 33%;
    align-items: baseline;
`;

export const NotificationWrapper = styled.table`
    width: 100%;
`;

export const NotificationRow = styled.tr`

`;
export const NotificationData = styled.td`
    // width: 33%;
`;