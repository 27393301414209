import { useState, useRef, useEffect } from "react";
import { Input, Select, Space } from "antd";
import { Services } from "../../../../__services";

const { TextArea } = Input;

export const AddEditConcept = ({ onChange, conceptId, conceptName, conceptDescription, conceptDataType }) => {
    const [allDatatype, setAllDatatype] = useState([]);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllDataType();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllDataType = async () => {
        try {
            let datatype = await Services.GetDataType();
            setAllDatatype(datatype.rows);
        } catch (err) {
            console.log('Fetch Datatype Exception :', err);
        }
    }

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input value={conceptName} onChange={e => onChange('name', e.target.value)} placeholder="Name" />
                <TextArea value={conceptDescription} rows={4} placeholder="Description" onChange={e => onChange('description', e.target.value)} />
                <Select
                    placeholder="Data Type"
                    value={conceptDataType}
                    style={{ width: '100%' }}
                    onChange={(value) => onChange('datatype', value)}
                    options={allDatatype.map(dt => { return { key: dt.id, value: dt.id, label: dt.name } })}
                />
                <p style={{color: "grey"}}>uuid: {conceptId}</p>
            </Space>
        </>
    )
}