import { useEffect, useRef, useState } from "react";
import { Card } from "antd";
import { IdcardOutlined } from '@ant-design/icons';
import { CONSTANT } from "../../../../Utils";

const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const PatientInformation = ({ data }) => {
    const [leftDiagnosis, setLeftDiagnosis] = useState([]);
    const [rightDiagnosis, setRightDiagnosis] = useState([]);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getDiagnosis()
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDiagnosis = () => {
        if (data) {
            let { obs } = data;
            let rightEyeLens = obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS);
            let leftEyeLens = obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS);
            let rightEyeAddPath = obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY);
            let leftEyeAddPath = obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY);
            setLeftDiagnosis([...leftEyeLens, ...leftEyeAddPath]);
            setRightDiagnosis([...rightEyeLens, ...rightEyeAddPath]);
        }
    }

    return (
        <div>
            <Card title={(<>
                <IdcardOutlined /> Patient Information
            </>
            )}>
                <div style={{ display: 'flex', justifyContent: 'start', gap: 20 }}>
                    <div>
                        <b>Name:</b> {`${data?.patient?.person?.first_name} ${data?.patient?.person?.middle_name || ''} ${data?.patient?.person?.last_name}`}
                    </div>
                    <div>
                        <b>Age:</b> {calculateAge(data?.patient?.person?.birthdate)}
                    </div>
                    <div>
                        <b>Sex:</b> {data?.patient?.person?.gender}
                    </div>
                    <div>
                        <b>Visilant_Id:</b> {data?.patient?.patient_identifier}
                    </div>
                </div>

                <div>
                    <b>Hospital Visit Date:</b> {new Date(data?.created_at)?.toLocaleDateString()}
                </div>

                <div>
                    <b>Provider:</b> {`${data?.creator?.first_name} ${data?.creator?.middle_name || ''} ${data?.creator?.last_name}`}
                </div>
                <div>
                    <b>Diagnosis:</b>
                    <div>R - {rightDiagnosis.map(dia => dia.obs_string.value)}</div>
                    <div>L - {leftDiagnosis.map(dia => dia.obs_string.value)}</div>
                </div>

            </Card>
        </div>
    )
}