import styled from "styled-components";

export const DiagnosisWrapper = styled.div`
    display: flex;
    gap: 10%;
`;

export const DiagnosisSection = styled.div`
    display: flex;
    justify-content: space-between;
`;