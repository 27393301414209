import { useEffect, useRef, useState } from "react";
import { DeleteFilled } from '@ant-design/icons';
import { SlitWrapper, SlitRow, SlitHead, SlitData, NotificationWrapper, NotificationRow, NotificationData } from './style';
import { Card, Input, Alert, notification, Checkbox } from "antd";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";
import { Services } from "../../../../__services";

export const SlitLampFinding = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();

    const [slitLensRightLampDiagnosis, setSlitLensRightLampDiagnosis] = useState([]);
    const [slitLensLeftLampDiagnosis, setSlitLensLeftLampDiagnosis] = useState([]);
    const [slitCorneaRightLampDiagnosis, setSlitCorneaRightLampDiagnosis] = useState([]);
    const [slitCorneaLeftLampDiagnosis, setSlitCorneaLeftLampDiagnosis] = useState([]);
    const [slitConjuctivaRightLampDiagnosis, setSlitConjuctivaRightLampDiagnosis] = useState([]);
    const [slitConjuctivaLeftLampDiagnosis, setSlitConjuctivaLeftLampDiagnosis] = useState([]);

    const [slitLensRight, setSlitLensRight] = useState();
    const [slitLensLeft, setSlitLensLeft] = useState();
    const [slitCorneaRight, setSlitCorneaRight] = useState();
    const [slitCorneaLeft, setSlitCorneaLeft] = useState();
    const [slitConjuctivaRight, setSlitConjuctivaRight] = useState();
    const [slitConjuctivaLeft, setSlitConjuctivaLeft] = useState();

    const [otherNoteDiagnosisRight, setOtherNoteDiagnosisRight] = useState([]);
    const [otherNoteDiagnosisLeft, setOtherNoteDiagnosisLeft] = useState([]);
    const [otherNoteRight, setOtherNoteRight] = useState();
    const [otherNoteLeft, setOtherNoteLeft] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getDiagnosis();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitDiagnosis();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteDiagnosis(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'rightEyeAllClear') {
            if (value) {
                setSlitConjuctivaRight('Clear')
                setSlitCorneaRight('Clear')
                setSlitLensRight('Clear')
            } else {
                setSlitConjuctivaRight('')
                setSlitCorneaRight('')
                setSlitLensRight('')
            }
        } else if (type === 'leftEyeAllClear') {
            if (value) {
                setSlitConjuctivaLeft('Clear')
                setSlitCorneaLeft('Clear')
                setSlitLensLeft('Clear')
            } else {
                setSlitConjuctivaLeft('')
                setSlitCorneaLeft('')
                setSlitLensLeft('')
            }
        }
    }

    const getDiagnosis = async () => {
        setSlitLensRightLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSRIGHT));
        setSlitLensLeftLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSLEFT));
        setSlitConjuctivaLeftLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVALEFT));
        setSlitConjuctivaRightLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVARIGHT));
        setSlitCorneaLeftLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEALEFT));
        setSlitCorneaRightLampDiagnosis(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEARIGHT));
        setOtherNoteDiagnosisRight(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISOTHERNOTERRIGHT));
        setOtherNoteDiagnosisLeft(data.obs.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISOTHERNOTERLEFT));
    }

    const submitDiagnosis = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (slitCorneaRight) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEARIGHT,
                obs_string: {
                    value: slitCorneaRight
                }
            })
        }
        if (slitCorneaLeft) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEALEFT,
                obs_string: {
                    value: slitCorneaLeft
                }
            })
        }
        if (slitLensRight) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSRIGHT,
                obs_string: {
                    value: slitLensRight
                }
            })
        }
        if (slitLensLeft) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSLEFT,
                obs_string: {
                    value: slitLensLeft
                }
            })
        }
        if (slitConjuctivaRight) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVARIGHT,
                obs_string: {
                    value: slitConjuctivaRight
                }
            })
        }
        if (slitConjuctivaLeft) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVALEFT,
                obs_string: {
                    value: slitConjuctivaLeft
                }
            })
        }
        if (otherNoteRight) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISOTHERNOTERRIGHT,
                obs_string: {
                    value: otherNoteRight
                }
            })
        }
        if (otherNoteLeft) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.DIAGNOSISOTHERNOTERLEFT,
                obs_string: {
                    value: otherNoteLeft
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let slitCorneaFindingRight = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEARIGHT);
                    let slitCorneaFindingLeft = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCORNEALEFT);
                    let slitLensFindingRight = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSRIGHT);
                    let slitLensFindingLeft = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGLENSLEFT);
                    let slitConjuctivaFindingRight = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVARIGHT);
                    let slitConjuctivaFindingLeft = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISSLITLAMPFINDINGCONJUNCTIVALEFT);
                    let otherNRight = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISOTHERNOTERRIGHT);
                    let otherNLeft = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISOTHERNOTERLEFT);
                    if (slitCorneaFindingRight.length) {
                        setSlitCorneaRightLampDiagnosis([...slitCorneaRightLampDiagnosis, ...slitCorneaFindingRight]);
                    }
                    if (slitCorneaFindingLeft.length) {
                        setSlitCorneaLeftLampDiagnosis([...slitCorneaLeftLampDiagnosis, ...slitCorneaFindingLeft]);
                    }
                    if (slitLensFindingRight.length) {
                        setSlitLensRightLampDiagnosis([...slitLensRightLampDiagnosis, ...slitLensFindingRight]);
                    }
                    if (slitLensFindingLeft.length) {
                        setSlitLensLeftLampDiagnosis([...slitLensLeftLampDiagnosis, ...slitLensFindingLeft]);
                    }
                    if (slitConjuctivaFindingRight.length) {
                        setSlitConjuctivaRightLampDiagnosis([...slitConjuctivaRightLampDiagnosis, ...slitConjuctivaFindingRight]);
                    }
                    if (slitConjuctivaFindingLeft.length) {
                        setSlitConjuctivaLeftLampDiagnosis([...slitConjuctivaLeftLampDiagnosis, ...slitConjuctivaFindingLeft]);
                    }
                    if (otherNRight.length) {
                        setOtherNoteDiagnosisRight([...otherNoteDiagnosisRight, ...otherNRight]);
                    }
                    if (otherNLeft.length) {
                        setOtherNoteDiagnosisLeft([...otherNoteDiagnosisLeft, ...otherNLeft]);
                    }
                    resetField();
                    openNotificationWithIcon('success', 'Slit lamp finding Submitted', '');
                }
            })
        }
    }

    const deleteDiagnosis = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'slitCorneaRightLampDiagnosis') {
                    let newData = [...slitCorneaRightLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitCorneaRightLampDiagnosis(newData);
                    }
                } else if (path === 'slitCorneaLeftLampDiagnosis') {
                    let newData = [...slitCorneaLeftLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitCorneaLeftLampDiagnosis(newData);
                    }
                } else if (path === 'slitLensRightLampDiagnosis') {
                    let newData = [...slitLensRightLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitLensRightLampDiagnosis(newData);
                    }
                } else if (path === 'slitLensLeftLampDiagnosis') {
                    let newData = [...slitLensLeftLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitLensLeftLampDiagnosis(newData);
                    }
                } else if (path === 'slitConjuctivaRightLampDiagnosis') {
                    let newData = [...slitConjuctivaRightLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitConjuctivaRightLampDiagnosis(newData);
                    }
                } else if (path === 'slitConjuctivaLeftLampDiagnosis') {
                    let newData = [...slitConjuctivaLeftLampDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setSlitConjuctivaLeftLampDiagnosis(newData);
                    }
                } else if (path === 'otherNoteDiagnosisRight') {
                    let newData = [...otherNoteDiagnosisRight];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setOtherNoteDiagnosisRight(newData)
                    }
                } else if (path === 'otherNoteDiagnosisLeft') {
                    let newData = [...otherNoteDiagnosisLeft];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setOtherNoteDiagnosisLeft(newData)
                    }
                }
                openNotificationWithIcon('success', 'Slit lamp finding Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setSlitLensRight('');
        setSlitLensLeft('');
        setSlitCorneaLeft('')
        setSlitCorneaRight('')
        setSlitConjuctivaLeft('');
        setSlitConjuctivaRight('');
        setOtherNoteRight('');
        setOtherNoteLeft('');
    }

    return (
        <div>
            <Card title={(<>
                Slit lamp findings
            </>
            )}>
                <SlitWrapper>
                    <thead>
                        <SlitRow>
                            <SlitHead></SlitHead>
                            <SlitHead>Right Eye</SlitHead>
                            <SlitHead><div><div>Clear?</div><Checkbox onChange={(e) => onChangeHandler('rightEyeAllClear', e.target.checked)}></Checkbox></div></SlitHead>
                            <SlitHead>Left Eye</SlitHead>
                            <SlitHead><div><div>Clear?</div><Checkbox onChange={(e) => onChangeHandler('leftEyeAllClear', e.target.checked)}></Checkbox></div></SlitHead>
                        </SlitRow>
                    </thead>
                    <tbody>
                        <SlitRow>
                            <SlitData style={{ width: '20%' }}>Conjuctiva/Sclera</SlitData>
                            <SlitData>
                                <Input value={slitConjuctivaRight} onChange={e => setSlitConjuctivaRight(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitConjuctivaRight('Clear') : setSlitConjuctivaRight('')}></Checkbox>
                            </SlitData>
                            <SlitData>
                                <Input value={slitConjuctivaLeft} onChange={e => setSlitConjuctivaLeft(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitConjuctivaLeft('Clear') : setSlitConjuctivaLeft('')} ></Checkbox>
                            </SlitData>
                        </SlitRow>
                        <SlitRow>
                            <SlitData style={{ width: '20%' }}>Cornea</SlitData>
                            <SlitData>
                                <Input value={slitCorneaRight} onChange={e => setSlitCorneaRight(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitCorneaRight('Clear') : setSlitCorneaRight('')}></Checkbox>
                            </SlitData>
                            <SlitData>
                                <Input value={slitCorneaLeft} onChange={e => setSlitCorneaLeft(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitCorneaLeft('Clear') : setSlitCorneaLeft('')}></Checkbox>
                            </SlitData>
                        </SlitRow>
                        <SlitRow>
                            <SlitData style={{ width: '20%' }}>Lens</SlitData>
                            <SlitData>
                                <Input value={slitLensRight} onChange={e => setSlitLensRight(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitLensRight('Clear') : setSlitLensRight('')}></Checkbox>
                            </SlitData>
                            <SlitData>
                                <Input value={slitLensLeft} onChange={e => setSlitLensLeft(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(e) => e.target.checked ? setSlitLensLeft('Clear') : setSlitLensLeft('')}></Checkbox>
                            </SlitData>
                        </SlitRow>
                        <SlitRow>
                            <SlitData style={{ width: '20%' }}>Other Notes</SlitData>
                            <SlitData>
                                <Input value={otherNoteRight} onChange={e => setOtherNoteRight(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData>
                            </SlitData>
                            <SlitData>
                                <Input value={otherNoteLeft} onChange={e => setOtherNoteLeft(e.target.value)} size="large" />
                            </SlitData>
                            <SlitData>
                            </SlitData>
                        </SlitRow>
                    </tbody>
                </SlitWrapper>

                <NotificationWrapper>
                    <tbody>
                        <NotificationRow>
                            <NotificationData style={{ width: '20%' }}></NotificationData>
                            <NotificationData style={{ width: '37%' }}>
                                {slitConjuctivaRightLampDiagnosis.map(diagnosis => <Alert key={diagnosis.id} type="info" message={(
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitConjuctivaRightLampDiagnosis')} style={{ color: 'red' }} />
                                    </div>)}
                                />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                            <NotificationData>
                                {slitConjuctivaLeftLampDiagnosis.map(diagnosis => <Alert key={diagnosis.id} type="info" message={(
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitConjuctivaLeftLampDiagnosis')} style={{ color: 'red' }} />
                                    </div>)}
                                />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                        </NotificationRow>
                        <NotificationRow>
                            <NotificationData style={{ width: '20%' }}></NotificationData>
                            <NotificationData style={{ width: '37%' }}>
                                {slitCorneaRightLampDiagnosis.map(diagnosis =>
                                    <Alert key={diagnosis.id} type="info" message={(
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitCorneaRightLampDiagnosis')} style={{ color: 'red' }} />
                                        </div>)}
                                    />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                            <NotificationData>
                                {slitCorneaLeftLampDiagnosis.map(diagnosis =>
                                    <Alert key={diagnosis.id} type="info" message={(
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitCorneaLeftLampDiagnosis')} style={{ color: 'red' }} />
                                        </div>)}
                                    />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                        </NotificationRow>
                        <NotificationRow>
                            <NotificationData style={{ width: '20%' }}></NotificationData>
                            <NotificationData style={{ width: '37%' }}>
                                {slitLensRightLampDiagnosis.map(diagnosis =>
                                    <Alert key={diagnosis.id} type="info" message={(
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitLensRightLampDiagnosis')} style={{ color: 'red' }} />
                                        </div>)}
                                    />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                            <NotificationData>
                                {slitLensLeftLampDiagnosis.map(diagnosis =>
                                    <Alert key={diagnosis.id} type="info" message={(
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'slitLensLeftLampDiagnosis')} style={{ color: 'red' }} />
                                        </div>)}
                                    />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                        </NotificationRow>
                        <NotificationRow>
                            <NotificationData style={{ width: '20%' }}></NotificationData>
                            <NotificationData style={{ width: '37%' }}>
                                {otherNoteDiagnosisRight.map(diagnosis =>
                                    <Alert key={diagnosis.id} type="info" message={(
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'otherNoteDiagnosisRight')} style={{ color: 'red' }} />
                                        </div>)}
                                    />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                            <NotificationData>
                                {otherNoteDiagnosisLeft.map(diagnosis => <Alert key={diagnosis.id} type="info" message={(
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{diagnosis.obs_string.value}</div>
                                        <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'otherNoteDiagnosisLeft')} style={{ color: 'red' }} />
                                    </div>)}
                                />
                                )}
                            </NotificationData>
                            <NotificationData></NotificationData>
                        </NotificationRow>
                    </tbody>
                </NotificationWrapper >
            </Card >
            {contextHolder}
        </div >
    )
}