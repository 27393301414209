import * as AuthHelper from './APIHelper';

export const GetProtocol = () => {
    return AuthHelper.getResponse('/licencekeyprotocol');
}

export const GetProtocolWithParams = (params) => {
    return AuthHelper.getResponse(`/licencekeyprotocol?${params}`);
}

export const PostProtocol = (payload) => {
    return AuthHelper.postResponse('/licencekeyprotocol', payload);
}

export const PutProtocol = (id, payload) => {
    return AuthHelper.putResponse(`/licencekeyprotocol/${id}`, payload);
}

export const DeleteProtocol = (id) => {
    return AuthHelper.deleteResponse(`/licencekeyprotocol/${id}`);
}