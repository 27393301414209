import { useEffect, useState, useRef } from "react";
import { Spin } from 'antd';
import { Services } from "../../__services";
import { useLocation, useParams } from "react-router-dom";
import { CONSTANT, Server, getFromLocalStorage } from "../../Utils";
import { notification } from 'antd';
import { UserVisitSummary } from "./User";
import { CoordinatorVisitSummary } from "./Coordinator";
import { TVLVisitSummary } from "./TVL";


export const VisitSummary = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [adultinitial, setAdultinitial] = useState();
    const [visitInProgress, setVisitInProgress] = useState();
    const [visitInProgressFirst, setVisitInProgressFirst] = useState();
    const [visitComplete, setVisitComplete] = useState();
    const [visitMicrobiology, setVisitMicrobiology] = useState();
    const [visitMicrobiologyResult, setVisitMicrobiologyResult] = useState();
    const [visitMicrobiologyComplete, setVisitMicrobiologyComplete] = useState();
    const [showInfectionFragment, setShowInfectionFragment] = useState(false);
    const [showMedicationFragment, setShowMedicationFragment] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [submitDiagnosis, setSubmitDiagnosis] = useState(false);
    const [submitInfectionHistory, setSubmitInfectionHistory] = useState(false);
    const [submitMedicalHistory, setSubmitMedicalHistory] = useState(false);
    const [submitCoordinator, setSubmitCoordinator] = useState();
    const isFirstRender = useRef(true);
    const { id } = useParams();

    useEffect(() => {
        if (isFirstRender.current) {
            getAllEncounters();
            isFirstRender.current = false
            return;
        }

        if (adultinitial && !visitInProgress) {
            postVisitNote();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adultinitial, visitInProgress])

    useEffect(() => {
        setTimeout(() => setSubmitInfectionHistory(false), 3000);
        setTimeout(() => setSubmitCoordinator(false), 3000);
    }, [submitDiagnosis, submitInfectionHistory, submitCoordinator])

    const onClickHandler = (type, value) => {
        if (type === 'visitComplete-diagnosis') {
            if (submitDiagnosis && showInfectionFragment) {
                setSubmitInfectionHistory(true);
            } else {
                setSubmitDiagnosis(true);
            }
            // setTimeout(() => {
            //     postVisitComplete();
            // }, 1000);
        } else if (type === 'visitNote') {
            setSubmitCoordinator(type);
        } else if (type === 'visitCompleteCoordinator') {
            setSubmitCoordinator(type);
            if (value) {
                setTimeout(() => {
                    postVisitComplete('coordinator');
                }, 1000);
            }
        } else if (type === 'tvl-submit') {
            setSubmitDiagnosis(true);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'showInfection') {
            setShowInfectionFragment(value);
        } else if (type === 'showMedicationHistory') {
            setShowMedicationFragment(value);
        } else if (type === 'submitInfectionHistory') {
            setSubmitInfectionHistory(true);
        } else if (type === 'submitMedicalHistory') {
            setSubmitMedicalHistory(true);
        } else if (type === 'submitEncounter') {
            postVisitComplete();
        } else if (type === 'submitDiagnosis') {
            setSubmitDiagnosis(value);
        } else if (type === 'followUp') {
            postFollowUpEncounter(value);
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getFirstVisit = (response) => {
        return new Promise((resolve, reject) => {
            try {
                if (response.rows[0].visit.visit_type_id === CONSTANT.VISITCOORDINATOR) {
                    let params = `patient_id=${response.rows[0].patient.id}&encounter_type_id=${CONSTANT.ENCOUNTERVISITINPROGRESS}&customQuery=obs(obs_string),visit,creator`
                    Services.GetEncounterWithParams(params)
                        .then(response => {
                            if (response.status !== 400 && response.status !== 401 && response.rows.length) {
                                let firstVisit = response.rows.find(encounter => encounter.visit.visit_type_id === CONSTANT.VISITFIRST);
                                if (firstVisit) {
                                    setVisitInProgressFirst(firstVisit);
                                    resolve();
                                } else {
                                    reject();
                                }
                            } else {
                                reject();
                            }
                        })
                } else {
                    resolve();
                }
            } catch (err) {
                console.log('Find First diagnosis :', err);
                reject();
            }
        })
    }

    const getAllEncounters = () => {
        try {
            let params = `visit_id=${id}&customQuery=patient(person),obs,creator,visit`;
            Services.GetEncounterWithParams(params)
                .then(async response => {
                    if (response.status !== 400 && response.status !== 401 && response.rows.length) {
                        try {
                            // await getFirstVisit(response);
                        } catch(err) {
                            console.log('No first Visit Exception :', err);
                        }
                        setAdultinitial(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERADULTINITIAL));
                        let visitINProgress = response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                        try {
                            if (visitINProgress) {
                                let diagnosisObs = visitINProgress.obs.filter(visit => [CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY, CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY].includes(visit.concept_id))
                                if (diagnosisObs.length) {
                                    diagnosisObs.forEach(obs => {
                                        if (obs.obs_string.value.match('Active corneal infection')) {
                                            setShowInfectionFragment(true);
                                        }
                                    })
                                }
                            }
                        } catch (err) {
                            console.log('Find Diagnosis :', err);
                        }
                        setVisitInProgress(visitINProgress);
                        setVisitComplete(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERVISITCOMPLETE));
                        setVisitMicrobiology(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGY));
                        let visitMicrobiologyResult = response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS);
                        try {
                            if (!visitMicrobiologyResult && location.state.user_type !== 'user') {
                                let user = await getFromLocalStorage('user');
                                let payload = {
                                    encounter_type_id: CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS,
                                    creator_id: user.user.person_id,
                                    patient_id: response.rows[0]?.patient_id,
                                    visit_id: id,
                                }
                                Services.PostEncounter(payload).then(response => {
                                    if (response.status !== 400) {
                                        setVisitMicrobiologyResult({...response, obs: []})
                                    }
                                })
                            } else {
                                setVisitMicrobiologyResult(visitMicrobiologyResult)
                            }
                        } catch (err) {
                            console.log('Creating encounter error :', err);
                        }
                        setVisitMicrobiologyComplete(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE));
                        setLoading(false);
                    } else {
                        openNotificationWithIcon('error', 'Server Issue', '');
                        setLoading(false);
                    }
                })
        } catch (err) {
            console.log('Get Encounter exception :', err)
        }
    }

    const postVisitNote = async () => {
        try {
            let user = await getFromLocalStorage('user');
            let payload = {
                encounter_type_id: CONSTANT.ENCOUNTERVISITINPROGRESS,
                creator_id: user.user.person_id,
                patient_id: adultinitial.patient_id,
                visit_id: id,
            }
            Services.PostEncounter(payload).then(response => {
                if (response.status !== 400 && response.status !== 401) {
                    setVisitInProgress(response)
                    openNotificationWithIcon('success', 'Visit Note Created', '');
                    setLoading(false);
                }
            })
        } catch (err) {
            console.log('Post Visit Note Exception :', err);
        }
    }

    const postFollowUpEncounter = async (type) => {
        try {
            let user = await getFromLocalStorage('user');
            let payload = {
                encounter_type_id: type === 'baseHospitalFollowUp' ? CONSTANT.ENOCUNTERBASEHOSPITALFOLLOWUP : CONSTANT.ENCOUNTERHEALTHWORKERFOLLOWUP ,
                creator_id: user.user.person_id,
                patient_id: adultinitial.patient_id,
                visit_id: id,
            }
            Services.PostEncounter(payload).then(response => {
                if (response.status !== 400 && response.status !== 401) {
                    openNotificationWithIcon('success', type === 'baseHospitalFollowUp' ? 'Base Hospital Follow UP Created' : 'Health Worker Follow Up Created', '');
                }
            })
        } catch (err) {
            console.log('Post Encounter Exception :', err);
        }
    }

    const postVisitCoordinator = async (user) => {
        if (location.state.user_type === 'user' && showInfectionFragment) {
            // let payload = {
            //     patient_id: adultinitial.patient_id,
            //     visit_type_id: CONSTANT.VISITCOORDINATOR,
            //     location_id: user.user.person.location_id,
            //     creator_id: user.user.person_id
            // }
            // Services.PostVisit(payload).then(response => {
            //     if (response.status !== 400 && response.status !== 401) {
            //         let payload = {
            //             encounter_type_id: CONSTANT.ENCOUNTERADULTINITIAL,
            //             creator_id: user.user.person_id,
            //             patient_id: adultinitial.patient_id,
            //             visit_id: response.id,
            //         }
            //         Services.PostEncounter(payload).then(response => {
            //             if (response.status !== 400 && response.status !== 401) {
            //             }
            //         })
            //     }
            // })
            let payload = {
                encounter_type_id: CONSTANT.ENCOUNTERMICROBIOLOGY,
                creator_id: user.user.person_id,
                patient_id: adultinitial.patient_id,
                visit_id: id,
            }
            Services.PostEncounter(payload).then(response => {
                if (response.status !== 400 && response.status !== 401) {
                }
            })
        }
    }

    const postVisitComplete = async (type = 'visit') => {
        try {
            let user = await getFromLocalStorage('user');
            if ((type === 'visit' & !visitComplete) || (type === 'coordinator' & !visitMicrobiologyComplete)) {
                let payload = {
                    encounter_type_id: type === 'coordinator' ? CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE : CONSTANT.ENCOUNTERVISITCOMPLETE,
                    creator_id: user.user.person_id,
                    patient_id: adultinitial.patient_id,
                    visit_id: id,
                }
                Services.PostEncounter(payload).then(response => {
                    if (response.status !== 400 && response.status !== 401) {
                        setVisitComplete(response)
                        openNotificationWithIcon('success', 'Visit Complete', '');
                        setLoading(false);
                        postVisitCoordinator(user);
                    }
                })
            } else if (showInfectionFragment) {
                postVisitCoordinator(user);
            } else {
                openNotificationWithIcon('info', 'Visit Already Complete', '');
            }
        } catch (err) {
            console.log('Post Visit Complete Exception :', err);
        }
    }

    return (
        <>
            {loading && !adultinitial ?
                <Spin tip="Loading" size="large">
                    <div className="content" />
                </Spin> :
                <>
                    {Server.ENV.type === 'tvl' ? 
                        <TVLVisitSummary 
                            adultinitial={adultinitial} 
                            visitInProgress={visitInProgress}
                            onClickHandler={onClickHandler}
                            onChangeHandler={onChangeHandler}
                            submitDiagnosis={submitDiagnosis} /> : 
                        location.state.user_type === 'user' ?
                        <UserVisitSummary
                            adultinitial={adultinitial}
                            visitInProgress={visitInProgress}
                            onChangeHandler={onChangeHandler}
                            onClickHandler={onClickHandler}
                            submitDiagnosis={submitDiagnosis}
                            submitInfectionHistory={submitInfectionHistory}
                            submitMedicalHistory={submitMedicalHistory}
                            showInfectionFragment={showInfectionFragment} 
                            showMedicationFragment={showMedicationFragment} /> :
                        visitMicrobiologyResult ? <CoordinatorVisitSummary
                            visitInProgress={visitInProgress}
                            visitInProgressFirst={visitInProgressFirst}
                            visitMicrobiology={visitMicrobiology}
                            visitMicrobiologyResult={visitMicrobiologyResult}
                            visitMicrobiologyComplete={visitMicrobiologyComplete}
                            onClickHandler={onClickHandler}
                            submitCoordinator={submitCoordinator} /> : null
                    }
                    {contextHolder}
                </>
            }
        </>
    )
}