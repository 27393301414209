import { Card, Radio, Space, notification, Input, Alert } from "antd";
import { DeleteFilled, TeamOutlined } from '@ant-design/icons'
import { SectionWrapper, Section } from './style';
import { useEffect, useRef, useState } from "react";
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";

const calculateDays = (value) => {
    let todayDate = new Date();
    var result = todayDate.setDate(todayDate.getDate() + Number(value));
    return new Date(result)
}

const referralList = ['Healthworker to follow up at patient\'s home', 'Refer to base hospital',
    'Close visit and continue treatment regimen in community', 'No further care required ']

export const TVLReferral = ({ data, submit, onChange }) => {
    const [api, contextHolder] = notification.useNotification();

    const [allReferral, setAllReferral] = useState([]);
    const [allReferralTime, setALLReferralTime] = useState([]);
    const [followUp, setFollowUp] = useState('');
    const [followUpTime, setFollowUpTime] = useState('');

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getReferral();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitReferral();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteReferral') {
            deleteReferral(value, path);
        } else if (type === 'deleteReferralTime') {
            deleteReferral(value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'followUp') {
            setFollowUp(value);
        } else if (type === 'followUpTime') {
            setFollowUpTime(value);
        }
    }

    const getReferral = async () => {
        setAllReferral(data.obs.filter(ob => ob.concept_id === CONSTANT.REFERRAL));
        setALLReferralTime(data.obs.filter(ob => ob.concept_id === CONSTANT.REFERRALTIME))
    }

    const submitReferral = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (followUp.length) {
            if (followUp === 'Healthworker to follow up at patient\'s home') {
                onChange('followUp', 'healthWorkerFollowUp')
            } else if (followUp === 'Refer to base hospital') {
                onChange('followUp', 'baseHospitalFollowUp')
            } else {
                onChange('submitEncounter')
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.REFERRAL,
                obs_string: {
                    value: followUp
                }
            })
        }
        if ((followUp === 'Healthworker to follow up at patient\'s home' && followUpTime.length) || allReferral.find(ref => ref.obs_string.value === 'Healthworker to follow up at patient\'s home') && followUpTime.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.REFERRALTIME,
                obs_string: {
                    value: calculateDays(followUpTime)
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let referral = response.filter(ob => ob.concept_id === CONSTANT.REFERRAL);
                    let referralTime = response.filter(ob => ob.concept_id === CONSTANT.REFERRALTIME);
                    if (referral.length) {
                        setAllReferral([...allReferral, ...referral]);
                    }
                    if (referralTime.length) {
                        setALLReferralTime([...allReferralTime, ...referralTime]);
                    }
                    resetField();
                }
            })
        }
    }

    const deleteReferral = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'referral') {
                    let index = allReferral.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        let newData = [...allReferral]
                        newData.splice(index, 1);
                        setAllReferral(newData);
                    }
                } else if (path === 'referralTime') {
                    let index = allReferralTime.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        let newData = [...allReferralTime]
                        newData.splice(index, 1);
                        setALLReferralTime(newData);
                    }
                }
                openNotificationWithIcon('success', 'Referral Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setFollowUp('');
        setFollowUpTime('');
    }

    return (<div>
        <Card title={(<>
            <TeamOutlined /> Referral
        </>
        )}>
            <SectionWrapper>
                <Section>
                    <h3>What is the follow-up regimen for this patient?</h3>
                    <Radio.Group value={followUp} disabled={allReferral.length} onChange={(e) => onChangeHandler('followUp', e.target.value)}>
                        <Space direction="vertical">
                            {referralList.map((list, index) => <Radio key={index} value={list}>{list}</Radio>)}
                        </Space>
                    </Radio.Group>
                </Section>
                {followUp === 'Healthworker to follow up at patient\'s home' || (allReferral.length && allReferral.find(ref => ref.obs_string.value === 'Healthworker to follow up at patient\'s home')) ? <Section>
                    <h3>When should the healthworker follow up?</h3>
                    <Input type="number" min={1} disabled={allReferralTime.length} value={followUpTime} onChange={e => onChangeHandler('followUpTime', e.target.value)} placeholder="Days" />
                </Section> : null}
            </SectionWrapper>
            {allReferral.map(ref => <div key={ref.id}><br /><Alert type="info" message={
                (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>{ref.obs_string.value}</div>
                        <DeleteFilled onClick={() => onClickHandler('deleteReferral', ref.id, 'referral')} style={{ color: 'red' }} />
                    </div>)
            } /></div>
            )}
            {allReferralTime.map(ref => <div key={ref.id}><br /><Alert type="info" message={
                (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>{new Date(ref.obs_string.value).toDateString()}</div>
                        <DeleteFilled onClick={() => onClickHandler('deleteReferralTime', ref.id, 'referralTime')} style={{ color: 'red' }} />
                    </div>)
            } /></div>
            )}
        </Card>
        {contextHolder}
    </div>
    )
}